import { t } from "i18next";
import { useEffect, useState } from "react";
import { Checkbox, CircularProgress, Collapse, TextField } from "@mui/material";
import { green } from "@mui/material/colors";
import { createAffiliate, getAffiliatePayments } from "../helper/functions";
import { useStateContext } from "../contexts/ContextProvider";
import Button from "../components/Generics/Button";
import CopyToClipboard from "react-copy-to-clipboard";
import { Copy } from "../helper/icons";

const Affiliate = () => {

  const { affiliate } = useStateContext();

  const [state, setState] = useState(affiliate?.accepted ? "dashboard" : "conditions");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col w-full items-center pt-10 pb-20 px-2">
      {state === "conditions" && <Conditions changeState={setState}/>}
      {state === "data" && <Data changeState={setState}/>}
      {state === "dashboard" && <Dashboard />}
    </div>
  )
};

const Conditions = ({changeState}) => {

  const [accepted, setAccepted] = useState(false);
  const [extended, setExtended] = useState(false);

  return (
    <div className="flex flex-col w-full items-center ssm:pt-10 pb-20 px-2">
      <p className="text-3xl text-center">{t('Affiliate.Header')}</p>
      <p className="italic text-center">{t('Affiliate.Sub') + ":"}</p>
      <div className="flex flex-col w-full ssm:w-2/3 gap-1">
        <p className="italic mt-5 ssm:mt-10 font-bold ssm:text-lg">{t('Affiliate.Zusammenfassung')}</p>
        <p className="text-justify">{t('Affiliate.Bullet1')}</p>
        <p className="text-justify">{t('Affiliate.Bullet2')}</p>
        <p className="text-justify">{t('Affiliate.Bullet3')}</p>
        <p className="text-justify">{t('Affiliate.Bullet4')}</p>
        <p className="text-justify">{t('Affiliate.Bullet5')}</p>
        <p className="text-justify">{t('Affiliate.Bullet6')}</p>
        <p className="text-justify">{t('Affiliate.Bullet7')}</p>
        <p className="text-justify">{t('Affiliate.Bullet8')}</p>
        <p className="text-justify">{t('Affiliate.Bullet9')}</p>
      </div>

      <p onClick={() => setExtended(!extended)} className="italic underline cursor-pointer w-full ssm:w-2/3 text-allimmoDark text-lg mt-3">{t('Affiliate.DetailsAnzeigen')}</p>
      
      <Collapse className="w-full ssm:w-2/3 items-center" in={extended}>
        <div className="flex flex-col w-full mt-8">
          <p className="italic font-bold text-lg mb-2">{t('Affiliate.Vertragsschluss')}</p>
          <p className="pl-5">{t('Affiliate.Vertrag1')}</p>
          <p className="mt-3 pl-5">{t('Affiliate.Vertrag2')}</p>
          <p className="mt-3 pl-5">{t('Affiliate.Vertrag3')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic font-bold text-lg mb-2">{t('Affiliate.Kontobedingungen')}</p>
          <p className="pl-5">{t('Affiliate.Konto1')}</p>
          <p className="pl-5">{t('Affiliate.Konto2')}</p>
          <p className="pl-5">{t('Affiliate.Konto3')}</p>
          <p className="pl-5">{t('Affiliate.Konto4')}</p>
          <p className="pl-5">{t('Affiliate.Konto5')}</p>
          <p className="pl-5">{t('Affiliate.Konto6')}</p>
          <p className="pl-5">{t('Affiliate.Konto7')}</p>
          <p className="pl-5">{t('Affiliate.Konto8')}</p>
          <p className="pl-5">{t('Affiliate.Konto9')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.LinksUndGrafiken')}</p>
          <p className="pl-5">{t('Affiliate.LinksUndGrafiken1')}</p>
          <p className="pl-5 mt-3">{t('Affiliate.LinksUndGrafiken2')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.ProvisionUndBezahlung')}</p>
          <p className="pl-5">{t('Affiliate.Provision1')}</p>
          <p className="pl-5">{t('Affiliate.Provision2')}</p>
          <p className="pl-5 mt-3">{t('Affiliate.Provision3')}</p>
          <p className="pl-5">{t('Affiliate.Provision4')}</p>
          <p className="pl-5">{t('Affiliate.Provision5')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.Identifizierung')}</p>
          <p className="pl-5">{t('Affiliate.Identifizierung1')}</p>
          <p className="pl-5 mt-3">{t('Affiliate.Identifizierung2')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.Zahlung')}</p>
          <p className="pl-5">{t('Affiliate.Zahlung1')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.Rechte')}</p>
          <p className="pl-5">{t('Affiliate.RechteSub')}</p>
          <p className="pl-10 mt-3">{t('Affiliate.Rechte1')}</p>
          <p className="pl-10">{t('Affiliate.Rechte2')}</p>
          <p className="pl-10">{t('Affiliate.Rechte3')}</p>
          <p className="pl-10">{t('Affiliate.Rechte4')}</p>
          <p className="pl-10">{t('Affiliate.Rechte5')}</p>
          <p className="pl-10">{t('Affiliate.Rechte6')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.Gesetze')}</p>
          <p className="pl-5">{t('Affiliate.Gesetze1')}</p>
          <p className="pl-5 mt-3">{t('Affiliate.Gesetze2')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.Laufzeit')}</p>
          <p className="pl-5">{t('Affiliate.Laufzeit1')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.Beendigung')}</p>
          <p className="pl-5">{t('Affiliate.Beendigung1')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.Beziehungen')}</p>
          <p className="pl-5">{t('Affiliate.Beziehungen1')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.Haftung')}</p>
          <p className="pl-5">{t('Affiliate.Haftung1')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.Haftungsausschluss')}</p>
          <p className="pl-5">{t('Affiliate.Haftungsausschluss1')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.Datenschutz')}</p>
          <p className="pl-5">{t('Affiliate.Datenschutz1')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.Prüfung')}</p>
          <p className="pl-5">{t('Affiliate.Prüfung1')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.Schiedsgericht')}</p>
          <p className="pl-5">{t('Affiliate.Schiedsgericht1')}</p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <p className="italic mb-2 font-bold text-lg">{t('Affiliate.Sonstiges')}</p>
          <p className="pl-5">{t('Affiliate.Sonstiges1')}</p>
          <p className="pl-5">{t('Affiliate.Sonstiges2')}</p>
        </div>

      </Collapse>

      <div onClick={() => setAccepted(!accepted)} className="flex flex-row gap-2 items-center cursor-pointer mt-10 mb-3">
        <Checkbox checked={accepted} style={{transform: `scale(1.2)`}} sx={{color: green[600], '&.Mui-checked': {color: green[600]}}}/>
        <p className="sm:text-lg font-bold text-allimmoDark">{t('Affiliate.AcceptText')}</p>
      </div>

      <Button text={t('Affiliate.Weiter')} isDisabled={!accepted} clickFunction={() => changeState("data")}/>
      
    </div>
  )
};

const Data = ({changeState}) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState({
    accepted: true,
    name: "",
    bank: {
      name: "",
      iban: "",
      bic: "",
    },
    birthday: "",
    address: {
      street: "",
      number: "",
      zip: "",
      city: ""
    }
  });
  const [isLoading, setIsLoading] = useState(false);

  const validationCheck = () => {
    return data.name !== "" && data.bank.name !== "" && data.bank.bic !== "" && data.bank.iban !== "" && data.birthday !== "" && data.address.city !== "" && data.address.number !== "" && data.address.street !== "" && data.address.zip !== "";
  };

  const create = async () => {
    setIsLoading(true);
    await createAffiliate({affiliateData: data})
    .then(() => {
      changeState("dashboard");
      setIsLoading(false);
    })
  };

  return (
    <>
    {isLoading ? 
    <div className="w-full flex flex-row justify-center items-center">
      <CircularProgress size={60} className="text-allimmoDark"/>
    </div> :
    
    <div className="w-full ssm:w-2/3 lg:w-1/3 flex flex-col items-center gap-2">
      <p className="italic mb-3 ssm:mb-10 text-lg w-full text-center">{t('Affiliate.DatenText')}</p>
      <TextField required autoComplete="off" className="bg-white rounded w-full" size="medium" label={t('Affiliate.Name')} value={data.name} onChange={(e) => setData({...data, name: e.target.value})}/>
      <TextField InputLabelProps={{ shrink: true }}  type="date" required autoComplete="off" className="bg-white rounded w-full" size="medium" label={t('Affiliate.Birthday')} value={data.birthday} onChange={(e) => setData({...data, birthday: e.target.value})}/>
      <div className="flex flex-row gap-2 items-center w-full">
        <TextField required autoComplete="off" className="bg-white rounded w-3/4" size="medium" label={t('Affiliate.Straße')} value={data.address.street} onChange={(e) => setData({...data, address: {...data.address, street: e.target.value}})}/>
        <TextField required autoComplete="off" className="bg-white rounded w-1/4" size="medium" label={t('Affiliate.Nummer')} value={data.address.number} onChange={(e) => setData({...data, address: {...data.address, number: e.target.value}})}/>
      </div>
      <div className="flex flex-row gap-2 items-center w-full">
        <TextField required autoComplete="off" className="bg-white rounded w-1/4" size="medium" label={t('Affiliate.Zip')} value={data.address.zip} onChange={(e) => setData({...data, address: {...data.address, zip: e.target.value}})}/>
        <TextField required autoComplete="off" className="bg-white rounded w-3/4" size="medium" label={t('Affiliate.City')} value={data.address.city} onChange={(e) => setData({...data, address: {...data.address, city: e.target.value}})}/>
      </div>
      <TextField required autoComplete="off" className="bg-white rounded w-full" size="medium" label={t('Affiliate.Bank')} value={data.bank.name} onChange={(e) => setData({...data, bank: {...data.bank, name: e.target.value}})}/>
      <TextField required autoComplete="off" className="bg-white rounded w-full" size="medium" label={t('Affiliate.Iban')} value={data.bank.iban} onChange={(e) => setData({...data, bank: {...data.bank, iban: e.target.value}})}/>
      <TextField required autoComplete="off" className="bg-white rounded w-full" size="medium" label={t('Affiliate.Bic')} value={data.bank.bic} onChange={(e) => setData({...data, bank: {...data.bank, bic: e.target.value}})}/>
      <p className="italic text-center mb-3">{t('Affiliate.Richtigkeit')}</p>
      <Button clickFunction={create} isDisabled={!validationCheck()} text={t('General.Weiter')}/>
    </div>}
    </>
  )
};

const Dashboard = () => {

  const { affiliate } = useStateContext();

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col w-full px-2 ssm:w-1/2">
      <p className="text-2xl text-center w-full">{t('Affiliate.Dashboard')}</p>
      <p className="text-center w-full text-sm mt-1">{t('Affiliate.AffiliateCodeInfo')}</p>
      <div className="my-10 flex flex-col justify-center items-center">
        
        <div className="flex flex-col">
          <p className="text-xs italic text-center w-full">{t('Affiliate.Code')}</p>
          <p className="text-3xl text-allimmoDark font-bold p-1 bg-allimmoDark/20 select-text">{affiliate?.code}</p>
          <CopyToClipboard text={affiliate?.code}>
            <div>{!copied && <Copy size={20} onClick={() => setCopied(true)} className="text-allimmoDark cursor-pointer w-full mt-1"/>}</div>
          </CopyToClipboard>
        </div>
        {copied && <p className="text-green-500 text-xs mt-1">{t('Affiliate.CodeKopiert')}</p>}
      </div>

      <p className="text-center mt-3 text-lg">{t('Affiliate.GeworbeneMitglieder')}</p>
      <p className="text-center text-3xl text-allimmoDark font-bold">{affiliate?.recruited?.length}</p>
      {affiliate?.recruited?.length > 0 && <p className="italic mt-4 text-sm w-full text-center">{t('Affiliate.InfoText')}</p>}
      <div className="flex flex-col gap-1 w-full mt-1">
        {affiliate?.recruited?.map((r, i) => {
          return (
            <Recruit key={i} data={r}/>
          )
        })}
      </div>
    </div>
  )
};

export default Affiliate;

const Recruit = ({data}) => {
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAmount(); 
  }, []);

  const getAmount = async () => {
    setIsLoading(true);
    await getAffiliatePayments({recruitId: data})
    .then((res) => {
      setAmount(res.data);
      setIsLoading(false);
    });
  };

  return (
    <div className="flex flex-row items-center w-full px-2 py-1 border rounded border-allimmoDark bg-white">
      <p className="grow italic text-sm">{data?.substr(0, 10) + "..."}</p>
      {isLoading ? <CircularProgress size={15} /> : <p>€ {parseFloat(amount).toFixed(2)}</p>}
    </div>
  )
};