import { createRef, useEffect, useState } from "react";
import { addFirestoreColl } from "../helper/functions";
import { CircularProgress, TextField } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { t } from "i18next";
import Button from "../components/Generics/Button";
import AnimationIcon from "../components/Generics/AnimationIcon.jsx";
import { useStateContext } from "../contexts/ContextProvider";
import validator from "validator";
import { auth } from "../firebase.config";
import ReCAPTCHA from "react-google-recaptcha";

const Support = () => {
  const { handleMenuState, customer } = useStateContext();

  const [contactData, setContactData] = useState({
    subject: "",
    message: "",
    email: auth.currentUser ? auth.currentUser.email : "",
    name: customer?.name ?? "",
  });
  const [captcha, setCaptcha] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);

  const recaptchaRef = createRef();

  useEffect(() => {
    recaptchaRef.current.reset();
  }, []);  

  const sendMessage = async () => {
    setIsLoading(true);

    await addFirestoreColl("messages", {
      ...contactData,
      created: Timestamp.fromDate(new Date()),
      customer: auth.currentUser ? auth.currentUser.uid : "anonym",
    });
    setSuccessMsg(true);
    return;
  };

  return (
    <div
      className={`flex flex-col items-center w-screen px-3 md:w-2/3 lg:w1/2 mt-24`}
    >
      {successMsg ? (
        <div className="flex flex-col items-center justify-center">
          <AnimationIcon
            _color={false}
            _iconSize={70}
            iconSrc={require("../assets/animations/confetti.json")}
          />
          <p className="mt-3 text-center text-lg font-bold">
            {t("Contact.VielenDank")}
          </p>
          <p className="text-center">{t("Contact.Success")}</p>
          <p
            onClick={() => handleMenuState("dashboard")}
            className="mt-10 hover:underline italic text-allimmoDark/80 cursor-pointer"
          >
            {t("Contact.GoToDash")}
          </p>
        </div>
      ) : (
        <>
          {isLoading ? (
            <CircularProgress sx={{ color: "#00547B" }} size={60} />
          ) : (
            <>
              <p className="mb-3 sm:mb-5 text-base sm:text-xl text-allimmoDark">
                {t("Contact.Header")}
              </p>
              <div className="flex flex-row items-center space-x-2 w-full">
                <TextField
                  autoComplete="off"
                  margin="dense"
                  size="small"
                  label={t("General.Name")}
                  className="w-full bg-white rounded-lg"
                  value={contactData.name}
                  onChange={(e) =>
                    setContactData({ ...contactData, name: e.target.value })
                  }
                />
                <TextField
                  autoComplete="off"
                  margin="dense"
                  size="small"
                  required
                  label={t("General.Email")}
                  className="w-full bg-white rounded-lg"
                  value={contactData.email}
                  onChange={(e) =>
                    setContactData({ ...contactData, email: e.target.value })
                  }
                />
              </div>
              <TextField
                autoComplete="off"
                margin="dense"
                size="small"
                required
                label={t("Contact.Betreff")}
                className="w-full bg-white rounded-lg"
                value={contactData.subject}
                onChange={(e) =>
                  setContactData({ ...contactData, subject: e.target.value })
                }
              />
              <TextField
                autoComplete="off"
                margin="dense"
                multiline
                required
                rows={5}
                size="small"
                label={t("Contact.Nachricht")}
                className="w-full bg-white rounded-lg"
                value={contactData.message}
                onChange={(e) =>
                  setContactData({ ...contactData, message: e.target.value })
                }
              />

              <div className="mt-2">
                <ReCAPTCHA
                ref={recaptchaRef}
                onChange={() => setCaptcha(true)}
                onExpired={() => setCaptcha(false)}
                onErrored={() => setCaptcha(false)}
                className="" sitekey="6LcTcsYqAAAAAC2mHVz5OepY2xS10SiInVUDXeUe" />
              </div>

              <div className="w-full flex flex-row justify-center mt-3">
                <Button
                  isDisabled={!captcha || contactData.message === "" || contactData.email === "" || contactData.subject === "" || !validator.isEmail(contactData?.email) || isLoading}
                  clickFunction={sendMessage}
                  text={t("Contact.Senden")}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Support;
