import { t } from 'i18next';
import { ArrowRightSolid, Bed, CircleDashed } from '../../helper/icons';

const Page2 = () => {
  return (
    <div className='flex flex-col items-center justify-center animate-fade gap-8 rounded-xl border border-gray-400 p-8 bg-white shadow-lg w-full'>
      <p className='text-center font-bold'>{t('Onboarding.Page2Header')}</p>
      
      <div className='flex flex-row items-center justify-center gap-3'>
        <div className='flex flex-row items-center justify-center p-4 border border-orange-300 rounded-xl shadow-md bg-orange-50'>
          <Bed size={70} className='text-orange-300 text-6xl'/>
        </div>

        <ArrowRightSolid size={70} className='text-orange-300 text-6xl hidden mmd:block'/>

        <div className='flex flex-row items-center justify-center p-4 border border-orange-300 rounded-xl shadow-md bg-orange-50'>
          <CircleDashed size={70} className='text-orange-300 animate-spin animate-duration-[2000ms] text-6xl'/>
        </div>
      </div>

      <p className='text-center italic text-sm'>{t('Onboarding.Page2Text')}</p>
    </div>
  )
}

export default Page2
