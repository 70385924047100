import { createContext, useContext, useState } from "react";

const DatabaseCountsContext = createContext();

export const DatabaseCountsProvider = ({ children }) => {
    const [customerObjectsCount, setCustomerObjectsCount] = useState(0);
    const [numberMatchesAllObjects, setNumberMatchesAllObjects] = useState(0);

    return (
        <DatabaseCountsContext.Provider
            value={{ 
                setCustomerObjectsCount,
                setNumberMatchesAllObjects,
                numberMatchesAllObjects, 
                customerObjectsCount,
            }}>
                {children}
        </DatabaseCountsContext.Provider>
    )
};

export const useDatabaseCountsContext = () => useContext(DatabaseCountsContext)
