import { CircularProgress, Dialog, TextField } from "@mui/material";
import { Timestamp, addDoc, arrayUnion, collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { useDeletionContext } from "../../contexts/DeletionProvider";
import { usePopupContext } from "../../contexts/PopupProvider";
import { useSelectionContext } from "../../contexts/SelectionProvider";
import { auth, firestore } from "../../firebase.config";
import { Transition, convertLongTimestamp, sendEmailNotification, sendMessage, updateFirestoreDoc } from "../../helper/functions";
import { ChatPartnetLeft, CloseX, MessageReadCheck, Send } from "../../helper/icons";
import Button from "../Generics/Button";

const ChatDetailsWindow = ({chatID, close}) => {

  const { customerChats, customer, customerObjects } = useStateContext();
  const { chatSelection } = useSelectionContext();
  const { setDeletedObject, setObjectDeleteConfirmOpen } = useDeletionContext();
  const { setObjectListingObject, setObjectListingOpen, setObjectDetailsOpen } = usePopupContext();

  const [messages, setMessages] = useState([]);
  const [newMessageText, setNewMessageText] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [offeredObject, setOfferedObject] = useState({});
  const [checkContactInfoOpen, setCheckContactInfoOpen] = useState(false);
  const [changedContactData, setChangedContactData] = useState({
    name: customer.name,
    phone: customer.phone,
  });
  //const [chatID, setChatID] = useState(chatSelection);

  const messagesRef = collection(firestore, "chats", chatID, "messages");
  
  const chatRef = doc(firestore, "chats", chatID);
  const chat = customerChats.find(c => c.id === chatID);
  const data = customerObjects.find(el => el.id === chat?.object);
  const chatPartnetId = chat?.members.find(e => e !== auth.currentUser.uid);
  const chatPartnerName = chat?.data[Object.keys(chat?.data).find(i => i !== auth.currentUser.uid)]?.name;
  const chatPartnetLeft = chat?.deletedMembers && chat?.deletedMembers?.some(deletedChat => deletedChat.member === chatPartnetId);

    useEffect(() => {
        try{
            onSnapshot(messagesRef, (result) => {
                setMessages(result.docs.map((doc) => ({...doc.data(), id: doc.id })));
            });
        } catch (err) {
            console.error(err.message);
        }

        // eslint-disable-next-line
    }, [chatID]);

    const sendNewMessage = async (premadeType = "no", premadeText = "") => {
        if(newMessageText === "" && premadeType === "no") {
            return;
        } else {
            setIsLoading(true);
            await sendMessage({chatID: chatID, messageText: premadeText === "" ? newMessageText : premadeText, premade: premadeType})
            .then(() => {
                setIsLoading(false);
                setNewMessageText("");
            })
            .catch(() => setIsLoading(false))
        }
    };

    const updatePremade = async (type) => {
        const premadeString = `sentPremade.${type}`;
        await updateDoc(chatRef, {
            [premadeString]: arrayUnion(auth.currentUser.uid),
        });
    };

    const enterKeyPress = (event) => {
        if (event.keyCode === 13 || event.which === 13) {
            sendNewMessage();
        }
    };

    const sendContactInfo = async () => {
        setIsLoading(true);
        await updateFirestoreDoc("customers", auth.currentUser.uid, {
            name: changedContactData.name,
            phone: changedContactData.phone
        })
        .then(async () => {
            await addDoc(messagesRef, {
                type: 'contact',
                label: t('General.Kontaktdaten'),
                email: auth.currentUser.email,
                name: changedContactData.name,
                phone: changedContactData.phone,
                created: Timestamp.fromDate(new Date()),
                sender: auth.currentUser.uid,
            })
            .then(() => {
                updatePremade("contact");
                sendEmailNotification({address: chat?.data[chat?.members.find(el => el !== auth.currentUser.uid)].email, template: "newMessage"});
            })
            
        })
        setCheckContactInfoOpen(false);
        setIsLoading(false);
    };

    const gotoObject = () => {
        if(chat?.agentID === auth.currentUser.uid) {
            // object
            setObjectDetailsOpen(true);
        } else {
            // listing
            setObjectListingObject(offeredObject);
            setObjectListingOpen(true);
        }
    };

    const handleDelete = (e) => {
        setDeletedObject(data?.id);
        setObjectDeleteConfirmOpen(true);
    };

  return (
    <div className={`w-full flex flex-col items-center h-full`}>
        <div className="w-full flex fle-row justify-end items-center">
            <CloseX onClick={close} size={20} className='cursor-pointer self-end text-allimmoDark/80 hover:text-allimmoDark active:text-allimmoDark/60'/>
        </div>
        <div className={`flex flex-col items-center w-full border-b -mt-1 border-allimmoDark mb-2`}>
            <div className={`flex flex-col w-full`}>
                {/* {showObjectBtn && <p className="text-sm tesxt-allimmoDark underline italic cursor-pointer" onClick={gotoObject}>Zum Objekt</p>} */}
                {/* <AnimationIcon _iconSize={18} iconSrc={require('../../assets/animations/link.json')}/> */}
                <p className={`grow text-allimmoDark text-lg`}>{chat?.address}</p>
            </div>
            <div className={`flex flex-row items-center w-full italic text-14 -mt-1`}>
                <p className="grow">{`${chatPartnerName}`}</p>
                {/* <p>{convertLongTimestamp(chat?.created.toDate())}</p> */}
            </div>
        </div>

        <div className={`mb-1 flex flex-col-reverse space-y-reverse gap-y-1 md:gap-y-2 w-full overflow-y-auto scroll-smooth pb-5`}>
            {messages.sort((a,b) => b.created - a.created).map((m,i) => {
                return (
                    <div key={i} className={`flex flex-row w-full ${m.sender !== auth.currentUser.uid ? "pl-8" : "pr-8"}`}>
                        {m.sender !== auth.currentUser.uid && <div className="grow"/>}
                        {m.type === "text" && <ChatMessage message={m} />}
                        {m.type === "contact" && <ContactInfoMessage message={m} />}
                    </div>
                )
            })}
        </div>
        {chatPartnetLeft && 
        <div className="flex flex-col items-center justify-center mt-5">
            <ChatPartnetLeft size={22} className='text-red-500' />
            <p className="text-red-500">{`${chatPartnerName} ${t('ChatDetails.UserLeftChat')}`}</p>
            <p className="text-red-400 text-sm italic">{`${convertLongTimestamp(chat?.deletedMembers.find(e => e.member === chatPartnetId).created.toDate())}`}</p>
        </div>}
        <div className={`flex flex-col w-full`}>
            {!chatPartnetLeft &&
            <div className={`flex flex-row gap-2 items-center`}>
                {/* <Calender className={`text-allimmoDark cursor-pointer hover:text-sky-500`} size={25} /> */}
                <TextField disabled={chatPartnetLeft} onKeyDown={enterKeyPress} placeholder={t('ChatDetails.Nachricht')} value={newMessageText} onChange={(e) => setNewMessageText(e.target.value)} size="small" className="w-full" autoComplete="off"/>
                {!isloading ? !chatPartnetLeft && <Send size={25} className={`text-allimmoDark cursor-pointer hover:text-sky-500`} onClick={() => sendNewMessage()} /> :
                <CircularProgress size={20} />}
            </div>}
            <div className={`flex flex-row gap-2 mt-2`}>
                {/* {!chat.sentPremade?.viewing.includes(auth.currentUser.uid) && <p onClick={() => sendNewMessage("viewing", t('VorgefertigteNachrichten.BesichtigungText'))} className="rounded md:rounded-xl px-3 py-1 sm:py-0 bg-slate-200 cursor-pointer hover:bg-slate-300">{t('VorgefertigteNachrichten.BesichtigungLabel')}</p>} */}
                {!chat?.sentPremade?.contact?.includes(auth.currentUser.uid) && <p onClick={() => setCheckContactInfoOpen(true)} className="rounded md:rounded-xl py-1 sm:py-0 px-2 sm:px-3 bg-sky-200 cursor-pointer hover:bg-sky-300">{t('VorgefertigteNachrichten.Kontaktdaten')}</p>}
            </div>
        </div>

        <Dialog open={checkContactInfoOpen} fullWidth={true} TransitionComponent={Transition}>
            <div className="flex flex-col justify-center items-center py-8 w-full px-5">
                <p className="text-xl">{t('ChatDetails.KontaktdatenÜberprüfen')}</p>
                <div className="flex flex-col items-center justify-center my-8 space-y-3 w-full">
                    <TextField className="w-full" size="small" label={t('General.Name')} value={changedContactData.name} onChange={(e) => setChangedContactData({...changedContactData, name: e.target.value})}/>
                    <TextField className="w-full" size="small" label={t('General.Telefonnummer')} value={changedContactData.phone} onChange={(e) => setChangedContactData({...changedContactData, phone: e.target.value})}/>
                    <p className="w-full text-slate-400">{auth.currentUser.email}</p>
                </div>
                <div className="flex flex-row justify-center items-center space-x-2">
                    <Button loading={isloading} clickFunction={sendContactInfo} text={t('General.Speichern')}/>
                    <Button clickFunction={() => setCheckContactInfoOpen(false)} text={t('General.Abbrechen')}/>
                </div>
            </div> 
        </Dialog>
    </div>
  )
};

const ChatMessage = ({message}) => {
    return (
        <div className={`flex flex-col gap-1 shadow-lg select-text w-full border pt-2 px-3 border-allimmoDark rounded-t-xl ${message.sender !== auth.currentUser.uid ? 'bg-slate-100 rounded-bl-2xl' : 'bg-blue-100 rounded-br-2xl'}`}>
            <p>{message.text}</p>
            <div className="flex flex-row items-center justify-between">
                {message?.sender === auth.currentUser.uid &&
                <div className="flex flex-row items-center text-11 italic text-gray-500 gap-0.5">
                    {message?.read && <MessageReadCheck size={11} />}
                    {message?.read && <p>{t('General.Gelesen')}</p>}
                </div>}
                <p className="w-full italic text-11 text-end mt-3">{convertLongTimestamp(message?.created.toDate())}</p>
            </div>
        </div>
    )
};

const ContactInfoMessage = ({message}) => {
    return (
        <div className={`flex flex-col w-full shadow-lg border pt-2 px-3 border-allimmoDark rounded-t-xl ${message.sender !== auth.currentUser.uid ? 'bg-green-100 rounded-bl-2xl' : 'bg-green-100 rounded-br-2xl'}`}>
            <p className="mb-1 underline">{message.label}</p>
            <p className="select-all">{message.name}</p>
            <p className="select-all">{message.email}</p>
            <p className="select-all">{message.phone}</p>
            <p className="w-full italic text-11 text-end mt-3">{convertLongTimestamp(message?.created.toDate())}</p>
        </div>
    )
};

export default ChatDetailsWindow
