import { CircularProgress, Dialog, TextField } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import MatchLogo from "../assets/MatchLogo.png";
import ChatDetails from "../components/Chats/ChatDetails";
import Cookies from "../components/Cookies";
import Button from "../components/Generics/Button";
import Popup from "../components/Generics/Popup";
import LoadingWindow from "../components/LoadingWindow";
import LoginHandler from "../components/LoginHandler";
import NotificationManager from "../components/NotificationManager";
import PopupManager from "../components/PopupManager";
import Topbar from "../components/Topbar";
import { useStateContext } from "../contexts/ContextProvider";
import { useLoadingContext } from "../contexts/LoadingProvider";
import { useSelectionContext } from "../contexts/SelectionProvider";
import { auth } from "../firebase.config";
import { Transition, updateFirestoreDoc } from "../helper/functions";
import About from "./About";
import Account from "./Account";
import Affiliate from "./Affiliate";
import Agb from "./Agb";
import Chats from "./Chats";
import Dashboard from "./Dashboard";
import Faq from "./Faq";
import Impressum from "./Impressum";
import Matching from "./Matching";
import NewObject from "./NewObject";
import NewSearch from "./NewSearch";
import Objects from "./Objects";
import PlattformRating from "./PlattformRating";
import Privacy from "./Privacy";
import Searches from "./Searches";
import Settings from "./Settings";
import Shop from "./Shop";
import Stats from "./Stats";
import Contact from "./Support";
import Team from "./Team";
import Tips from "./Tips";
import Updates from "./Updates";
import { ToastContainer } from 'react-toastify';
import Admin from "./Admin";
import SavedObjects from "./SavedObjects";
import Stats2 from "./Stats2";
import MatchAccept from "./MatchAccept";
import Onboarding from "../components/Popups/Onboarding";
import AI from "./AI";
import FloatingButton from "../components/FloatingButton";
import Leads from "./Leads";

const MemberArea = ({sub= ""}) => {

  const incompleteValues = [undefined, "", "-"];

  const {
    menuState,
    isLoading,
    customer,
    changeDisplayNameOpen,
    loginOpen,
    infoOpen,
    screenSize,
    setIsLoading, 
    handleMenuState
  } = useStateContext();
  const { isCreating } = useLoadingContext();
  const { chatSelection, setChatSelection } = useSelectionContext();

  const [showCookies, setShowCookies] = useState(false);
  const profileValues = [
    customer?.country,
    customer?.education,
    customer?.employment,
    customer?.gender,
    customer?.income,
    customer?.phone,
    customer?.yearOfBirth,
];
const profileMissing = profileValues.some(value => incompleteValues.includes(value));

const [profileCompleteDelay, setProfileCompleteDelay] = useState(false);

  
  useEffect(() => {
    setTimeout(() => {
      setProfileCompleteDelay(true);
    }, 3000);
  }, []);

  useEffect(() => {
    if(sub === "") {
      return handleMenuState("dashboard");
    } else {
      return handleMenuState(sub);
    }
  }, [sub]);

  useEffect(() => {
    setShowCookies((auth.currentUser && !customer?.settings?.cookies && !menuState.privacy));
  }, [customer]);
  

  const closePaymentResult = async () => {
    setIsLoading(false);
    await updateFirestoreDoc("customers", auth.currentUser.uid, {stripeStatus: ""});
  };

  return (
    <div className="w-full h-screen flex flex-col items-center pt-24">
      <ToastContainer autoClose={10000} position='top-center' theme='colored'/>
      {/* <FloatingButton /> */}
      <Helmet>
        <title>Allimmo Match</title>
        <meta name="description" content="Allimmo Match ist die neue Art der Immobiliensuche. Mit Allimmo Match findest Du automatisch Immobilien jeglicher Art. Inseriere deine Objekte oder erstelle Suchprofile jetzt kostenlos." />
        <meta name="keywords" content="Immobiliensuche, Immobilien, Immo, Wohnung, Haus, Grundstück" />
      </Helmet>
      {!menuState.matchaccept && <Topbar />}
      
      <div className="flex flex-row justify-center grow w-full bg-gradient-to-b from-white from-0% via-allimmoDark/5 via-60% to-allimmoDark/25 to-100%">
      
        {menuState.dashboard && <Dashboard />}
        {menuState.objects && <Objects />}
        {menuState.searches && <Searches />}
        {menuState.chats && <Chats />}
        {menuState.shop && <Shop />}
        {menuState.account && <Account />}
        {menuState.support && <Contact />}
        {menuState.stats && <Stats2 />}
        {menuState.rating && <PlattformRating />}
        {menuState.about && <About />}
        {menuState.privacy && <Privacy />}
        {menuState.agb && <Agb />}
        {menuState.impressum && <Impressum />}
        {menuState.settings && <Settings />}
        {menuState.matching && <Matching />}
        {menuState.faq && <Faq />}
        {menuState.updates && <Updates />}
        {menuState.newObject && <NewObject />}
        {menuState.newSearch && <NewSearch />}
        {menuState.affiliate && <Affiliate />}
        {menuState.team && <Team />}
        {menuState.tips && <Tips />}
        {menuState.admin && <Admin />}
        {menuState.saved && <SavedObjects />}
        {menuState.matchaccept && <MatchAccept />}
        {menuState.onboarding && <Onboarding />}
        {menuState.leads && <Leads />}
        {menuState.ai && <AI />}
        {/* {menuState.calendar && <Calendar/>} */}
      </div>

      {(auth.currentUser && menuState.dashboard) && 
      <NotificationManager />}
      <PopupManager />
      {showCookies && <Cookies />}

      <Dialog open={isCreating} fullWidth={false} TransitionComponent={Transition}>
        <LoadingWindow />
      </Dialog>

      <Dialog open={isLoading} fullWidth={false} TransitionComponent={Transition}>
        <div className="flex flex-col justify-center items-center px-5 sm:px-8 py-10 space-y-3 select-none sm:w-300">
          <CircularProgress size={50} />
          <p className='text-center text-sm sm:text-base'>{t('General.MomentBitte')}</p>
        </div>
      </Dialog>

      <Dialog open={customer?.stripeStatus !== undefined && customer?.stripeStatus !== ""} fullWidth={false} TransitionComponent={Transition}>
        <div className="flex flex-col justify-center items-center px-10 py-6 select-none ">
          <img
            className="cursor-pointer"
            width={120}
            src={MatchLogo}
            alt="ALLIMMO"
          />
          {customer?.stripeStatus === "success" && (
            <p className="mt-2">{t(`Payment.success`)}</p>
          )}
          {customer?.stripeStatus === "failed" && (
            <p className="mt-2">{t(`Payment.failed`)}</p>
          )}
          {customer?.stripeStatus === "success" && (
            <p className="mb-8">{t(`Payment.successMessage`)}</p>
          )}
          {customer?.stripeStatus === "failed" && (
            <p className="mb-8">{t(`Payment.failedMessage`)}</p>
          )}
          <Button text="OK" clickFunction={closePaymentResult} />
        </div>
      </Dialog>

      <Popup
        openState={chatSelection && chatSelection !== ""}
        content={<ChatDetails />}
        close={() => setChatSelection("")}
      />

      <Dialog
        open={changeDisplayNameOpen}
        fullWidth={true}
        TransitionComponent={Transition}
      >
        <SaveName />
      </Dialog>

      <Dialog
        open={loginOpen}
        //maxWidth={screenSize.width > 400 ? "xs" : "lg"}
        // fullScreen={screenSize.width > 400 ? false : true}
        // fullWidth={screenSize.width > 400 ? true : false}
        fullScreen={true}
        fullWidth={true}
        TransitionComponent={Transition}
      >
        <LoginHandler />
      </Dialog>

      <Dialog open={infoOpen} fullWidth={true} TransitionComponent={Transition}>
        <InfoContent />
      </Dialog>

      {/* <Popup/> */}

    </div>
  );
};

const SaveName = () => {
  const { setChangeDisplayNameOpen } = useStateContext();

  const [newName, setNewName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const saveName = async () => {
    setIsLoading(true);
    await updateFirestoreDoc("customers", auth.currentUser.uid, {
      name: newName,
    }).then(() => {
      setChangeDisplayNameOpen(false);
      setIsLoading(false);
    });
  };

  return (
    <div className="py-10 px-5 flex flex-col justify-center items-center select-none">
      <TextField
        size="small"
        label={t("Account.IhrName")}
        className="w-2/3"
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
      />
      <div className="flex flex-row space-x-2 mt-5">
        <Button
          isDisabled={newName === "" || newName.length < 3}
          loading={isLoading}
          clickFunction={saveName}
          text={t("General.Speichern")}
        />
        <Button
          clickFunction={() => setChangeDisplayNameOpen(false)}
          text={t("General.Schliessen")}
        />
      </div>
    </div>
  );
};

const InfoContent = () => {
  const { infoData, setInfoOpen } = useStateContext();

  return (
    <div className="flex flex-col justify-center items-center p-5">
      <p className="w-full text-center text-lg text-allimmoDark mb-5">
        {infoData.header}
      </p>
      <p className="w-full text-center mb-3">{infoData.text}</p>
      <Button text="OK" clickFunction={() => setInfoOpen(false)} />
    </div>
  );
};

export default MemberArea
