import { CircularProgress, Collapse, Grow } from '@mui/material';
import { Timestamp, arrayUnion, collection, doc, documentId, getCountFromServer, onSnapshot, query, where } from 'firebase/firestore';
import { t } from 'i18next';
import { Icon } from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';
import ReactQuill from 'react-quill';
import { useParams, useSearchParams } from 'react-router-dom';
import BaseInfos from '../components/Object/BaseInfos';
import CommercialInfo from '../components/Object/CommercialInfo';
import Facilities from '../components/Object/Facilities';
import OpenAreas from '../components/Object/OpenAreas';
import ShortInfo from '../components/Object/ShortInfo';
import Vacancy from '../components/Object/Vacancy';
import { useStateContext } from '../contexts/ContextProvider';
import { auth, firestore } from '../firebase.config';
import { convertShortTimestamp, decryptString, fetchChatId, updateFirestoreDoc } from '../helper/functions';
import { BurgerMenu, ChatBubble, CloseX, DeletedOrInactive, DocumentIcon, Expose, HeartMinus, HeartPlus, NoData, Plan, Share, Shield } from '../helper/icons';
import useOutsideClick from '../hooks/useOutsideClick';
import ChatDetails from '../components/Chats/ChatDetails';
import ChatDetailsWindow from '../components/Chats/ChatDetailsWindow';

const ObjectListing = () => {

    const [objectListingObject, setObjectListingObject] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [objectStatus, setObjectStatus] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();

    const hideAddress = objectListingObject?.fromInterface && objectListingObject?.deal === "sale";
    const hideAddressFlyToZoom = 12;
    const normalFlyToZoom = 18;

    const { id } = useParams();
    const mapRef = useRef(null);    

    delete Icon.Default.prototype._getIconUrl;

    Icon.Default.mergeOptions({
      iconRetinaUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
      iconUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
      shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
    });

    const objectLocation = [objectListingObject?.location.lat, objectListingObject?.location.lng];
    const clickMarker = new Icon({
      iconUrl: require('../assets/location-pin.png'),
      iconSize: [36, 36],
      iconAnchor: [16, 36],
    });

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      setIsLoading(true);
        getObjectCount();
    }, [auth.currentUser]);

    const getObjectCount = async () => {
      setIsLoading(true);
      const objectRef = collection(firestore, "objects");
      const objectQuery = query(objectRef, where(documentId(), "==", id));
      const objectSnap = await getCountFromServer(objectQuery);

      if(objectSnap.data().count <= 0) {
        setObjectStatus("noData")
      } else {
        if (await getActiveObjectCount()) {
          if((auth.currentUser && await getContactCount()) || await getShareableObjectCount()) {
            setObjectStatus("");
            getObjectData();
          } else {
            setObjectStatus("noPermission");
          }
        } else {
          setObjectStatus("inactiveOrDeleted");
        }
      }
      setIsLoading(false);
    };

    const getActiveObjectCount = async () => {
      const activeObjectRef = collection(firestore, "objects");
      const activeObjectQuery = query(activeObjectRef, where(documentId(), "==", id), where("deleted", "==", false), where("active", "==", true));
      const activeObjectSnap = await getCountFromServer(activeObjectQuery);

      return activeObjectSnap.data().count > 0;
    };

    const getShareableObjectCount = async () => {
      const shareableObjectRef = collection(firestore, "objects");
      const shareableObjectQuery = query(shareableObjectRef, where(documentId(), "==", id), where("shareable", "==", true));
      const shareableObjectSnap = await getCountFromServer(shareableObjectQuery);

      return shareableObjectSnap.data().count > 0;
    };

    const getContactCount = async () => {
      const contactsRef = collection(firestore, "contacts");
      const contactsQuery = query(contactsRef, where("searchUserId", "==", auth?.currentUser?.uid), where("object", "==", id), where("status", "==", "accepted"));
      const contactsSnap = await getCountFromServer(contactsQuery);
      
      return contactsSnap.data().count > 0;
    };

    const getObjectData = async () => {
      const offeredObjectRef = doc(firestore, "objects", id);
      onSnapshot(offeredObjectRef, (result) => {
        if(result.exists()) {
          setObjectListingObject({...result.data(), id: result.id});
        }
      }, (error) => {
        console.log(error.name);
      })
    };

    const ZoomHandler = () => {
    const map = useMap();

    useEffect(() => {
        flyToPosition(objectLocation, hideAddress ? hideAddressFlyToZoom : normalFlyToZoom);
    }, [])

    const flyToPosition = (coordinates, zoom) => {
        map.flyTo(coordinates, zoom, {
          animate: true,
          duration: 2,
        });
    };

    if(!objectLocation.some(el => el === 0)) {
      return  <Marker position={objectLocation} icon={clickMarker}/>
    } else {
      return null;
    }
    };

    return (
      <div className={`flex flex-row justify-center ${isLoading ? "items-center" : ""} w-full px-1 ssm:px-2 my-2 overflow-auto`}>
        {isLoading ? 
        <div className='flex flex-col items-center gap-3'>
          <CircularProgress size={50}/>
          <p>{"Lade Objektdaten..."}</p>
        </div> :
        objectListingObject === null ? <NotAvailableScreen status={objectStatus}/> :
        <div className='flex flex-col gap-2 w-full mdd:w-1100'>
          {!auth.currentUser && 
          <img className='self-center mb-3' width={180} src={'https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2FAllimmomatch.png?alt=media&token=e6afb35b-6fb7-46dd-831f-4dd1b233b598'} alt='ALLIMMO'/>}
          
          <div className={`flex flex-row justify-end w-full gap-2 items-center border rounded border-allimmoDark/80 px-2 ${!auth.currentUser ? "py-0.5" : ""}`}>
            {(auth.currentUser.uid === objectListingObject.customer && objectStatus === "") && <FavouriteChanger object={objectListingObject}/>}
            {(auth.currentUser && objectStatus === "") && <p className='truncate select-text text-base ssm:text-lg grow'>{`${hideAddress ? objectListingObject?.address?.street : objectListingObject?.address?.streetNumber}, ${objectListingObject?.address?.cityZip}`}</p>}
            {objectListingObject?.shareable && <Share size={14} className='text-allimmoDark/80'/>}
            <ObjectListingMenu comissionLink={objectListingObject?.comissionContractLink} exposeLink={objectListingObject?.exposeLink} />
          </div>
            <div className='h-400'>
              <PictureViewer pictures={objectListingObject?.pictureLinks} />
            </div>
            <div className='flex flex-col items-center w-full select-none'>
              {/* Object Info */}
              <div className={`flex flex-col justify-center w-full border rounded border-allimmoDark bg-allimmoDark/5`}>
                <div className='flex flex-col w-full px-2 pb-2'>
                  <ShortInfo editable={false} _objectData={objectListingObject} />
                  <div className='flex flex-col md:flex-row gap-1 mt-2'>
                      {objectListingObject?.type === "commercial" && 
                      <CommercialInfo editable={false} _objectData={objectListingObject} />}
                      <BaseInfos editable={false} _objectData={objectListingObject} />
                      <Facilities editable={false} _objectData={objectListingObject} />
                  </div>

                  {/* Object Location */}
                  {(auth.currentUser && objectStatus === "") &&
                  <div className='flex flex-col md:flex-row gap-1'>
                    <div className="w-full h-300 border border-allimmoDark/80 rounded overflow-hidden mt-2 z-10">
                      <MapContainer center={objectLocation} zoom={hideAddress ? hideAddressFlyToZoom : 6} maxZoom={hideAddress ? hideAddressFlyToZoom : normalFlyToZoom} ref={mapRef} zoomControl={false}>
                        <TileLayer
                          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <ZoomHandler />
                      </MapContainer>
                    </div>
                  </div>}

                  <div className={`flex flex-col md:flex-row gap-1 mt-2 ${(objectListingObject?.openAreas?.length > 0 || (objectListingObject?.deal === "sale" && objectListingObject?.type !== "land")) ? "block" : "hidden"}`}>
                    {/* Open Areas */}
                    {objectListingObject?.openAreas?.length > 0 &&
                    <OpenAreas _objectData={objectListingObject} />}

                    {/* Vacancy & Inspection Details */}
                    <Vacancy _objectData={objectListingObject} />
                  </div>
                </div>
                {/* Notes & Additional Information */}
                {objectListingObject?.notes && objectListingObject?.notes.trim() !== "" &&
                <div className='bg-allimmoDark/5 rounded flex flex-col p-0.5 mt-1 border border-allimmoDark/70'>
                  <ReactQuill theme='bubble' readOnly={true} value={objectListingObject?.notes}/>
                </div>}

                <div className='flex flex-row items-center gap-2 mt-2 px-2 pb-1'>
                    <p className='text-11 italic grow select-text'>{`${t('General.Id')}: ${objectListingObject?.id}`}</p>
                    <p className='text-12 italic'>{`${convertShortTimestamp(objectListingObject?.created?.toDate())}`}</p>
                </div>
              </div>
            </div>
        </div>}
        {auth.currentUser && <ObjectChat objectId={id} search={searchParams} object={objectListingObject?.id}/>}
      </div>
    ) 
};

const PictureViewer = ({pictures}) => {

  const openLink = (link) => {
    window.open(link, '_blank')
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex overflow-x-auto mt-2">
        {pictures?.map((picture, i) => (
          <img
            className={`w-auto h-350 mr-2.5 cursor-pointer hover:opacity-40 active:opacity-100 border border-black rounded`}
            key={i}
            src={typeof picture === "string" ? picture : picture?.url}
            alt={`Pic ${i + 1}`}
            onClick={() => openLink(typeof picture === "string" ? picture : picture?.url)}
          />
        ))}
      </div>
    </div>
  )
};

const NotAvailableScreen = ({status}) => {
  return (
    <div className={`${status !== "" ? "block" : "hidden"} flex flex-col w-full justify-center items-center`}>
      <img width={150} src={'https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2FAllimmomatch.png?alt=media&token=e6afb35b-6fb7-46dd-831f-4dd1b233b598'} alt='ALLIMMO'/>
      {status !== "" && <p className='text-lg text-center'>{t(`ObjectListing.AccessStates.${status}`)}</p>}
      {status !== "" && <p className='text-sm italic text-center mb-2'>{t(`ObjectListing.AccessStates.${status}Sub`)}</p>}
      {status === "noPermission" && <Shield size={45} className='text-allimmoDark/80'/>}
      {status === "noData" && <NoData size={45} className='text-allimmoDark/80'/>}
      {status === "inactiveOrDeleted" && <DeletedOrInactive size={45} className='text-allimmoDark/80'/>}
    </div>
  )
};

const FavouriteChanger = ({object}) => {
  const { customer } = useStateContext();
  const [isSaving, setIsSaving] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const color = "orange";

  const saveFavourite = () => {
    if(customer?.savedObjects?.some(e => e.objectId === object.id)) return;
    setIsSaving(true);
    updateFirestoreDoc("customers", auth.currentUser.uid, {savedObjects: arrayUnion({
        searchId: decryptString(searchParams.get('id')),
        searchName: decryptString(searchParams.get('search')),
        objectId: object.id, 
        address: object.address,
        created: Timestamp.fromDate(new Date())
      })
    })
    .then(() =>  {
      setIsSaving(false)
    })
    .catch(() => {
      setIsSaving(false);
    });
  };

  const removeFavourite = () => {
    setIsSaving(true);
    updateFirestoreDoc("customers", auth.currentUser.uid, {savedObjects: customer.savedObjects.filter(e => e.objectId !== object.id)})
    .then(() =>  {
      setIsSaving(false)
    })
    .catch(() => {
      setIsSaving(false);
    });
  };

  return (
    <div className={`flex flex-row items-center text-${color}-400 hover:text-${color}-600 active:text-${color}-400`}>
      {(customer?.savedObjects && customer?.savedObjects?.some(e => e.objectId === object.id) && !isSaving) ?
      <HeartMinus className='cursor-pointer ' onClick={removeFavourite} size={16}/> :
      <HeartPlus className='cursor-pointer' onClick={saveFavourite} size={16}/>}
    </div>
  )
};

const ObjectListingMenu = ({exposeLink, comissionLink, planLink}) => {

  const [extended, setExtended] = useState(false);
  const wrapperRef = useRef(null);

  const showMenu = (exposeLink && exposeLink.trim() !== "") || (comissionLink && comissionLink.trim() !== "") || (planLink && planLink.trim() !== "");

  useOutsideClick(wrapperRef, () => {
    setExtended(false);
  });

  return (
    <div className={`relative ${showMenu ? "block" : "hidden"}`}>
      <BurgerMenu onClick={() => setExtended(!extended)} size={22} className='cursor-pointer text-allimmoDark/60 hover:text-allimmoDark active:text-allimmoDark/60'/>
      {extended &&
      <div ref={wrapperRef} className='absolute top-8 right-0 w-200 bg-white rounded border border-allimmoDark/80 p-1 z-10 text-allimmoDark/80 text-sm'>
        {exposeLink && exposeLink === "" && 
        <MenuElement text={t('ObjectListing.Menu.ShowExpose')} onClick={() => window.open(exposeLink, '_blank')} icon={<Expose size={24} className='hover:bg-allimmoDark/60 hover:text-white active:bg-allimmoDark/60 active:text-white p-0.5 rounded cursor-pointer'/>}/>}
        {comissionLink && comissionLink !== "" && 
        <MenuElement text={t('ObjectListing.Menu.ShowCommission')} onClick={() => window.open(exposeLink, '_blank')} icon={<DocumentIcon size={26} className='hover:bg-allimmoDark/60 hover:text-white active:bg-allimmoDark/60 active:text-white p-0.5 rounded cursor-pointer'/>}/>}
        {planLink && planLink !== "" && 
        <MenuElement text={t('ObjectListing.Menu.ShowPlan')} onClick={() => window.open(exposeLink, '_blank')} icon={<Plan size={26} className='hover:bg-allimmoDark/60 hover:text-white active:bg-allimmoDark/60 active:text-white p-0.5 rounded cursor-pointer'/>}/>}
      </div>}
    </div>
  )
};

const MenuElement = ({text, clickFunction, icon}) => {
  return (
    <div className='flex flex-row gap-2 items-center w-full hover:bg-allimmoDark/60 hover:text-white active:bg-allimmoDark/60 active:text-white p-1 rounded cursor-pointer'>
      {icon}
      <p onClick={clickFunction}>{text}</p>
    </div>
  )
};

const ObjectChat = ({search, object}) => {

  const [chatFound, setChatFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chatId, setChatId] = useState();
  const [chatVisible, setChatVisible] = useState(false);

  useEffect(() => {
    getChat();
  }, []);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    setChatVisible(false);
  });

  const getChat = async () => {
    try {
      setIsLoading(true);
      const result = await fetchChatId({searchId: search, objectId: object});
      setChatId(result.data.chatId);
      setIsLoading(false);
      setChatFound(true);
    } catch (error) {
      console.log(error.message);
    }
    return;
  };
  
  return (
    <div className={`${(!isLoading && !chatId) ? "hidden": "block"} z-50 flex flex-col justify-end items-center select-none fixed bottom-3 right-6 ssm:bottom-5 ssm:right-10`}>
      {!chatVisible &&
      <div className={`bg-white rounded-full p-2 ${isLoading ? "border-2" : "border-4"} border-allimmoDark/60`}>
        {(!isLoading && chatId) && <ChatBubble onClick={() => setChatVisible(true)} size={50} className=' cursor-pointer animate-pulse hover:animate-none text-allimmoDark/80 hover:text-allimmoDark active:text-allimmoDark/60'/>}
        {isLoading && <CircularProgress size={50}/>}
      </div>}
      {chatVisible &&
      <div ref={wrapperRef} className='flex relative flex-col gap-1 w-368 h-500 bg-white rounded-xl border-2 border-allimmoDark/80 p-2'>
        <ChatDetailsWindow chatID={chatId} close={() => setChatVisible(false)}/>
      </div>}
    </div>
  )
};

export default ObjectListing
