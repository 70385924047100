import { Collapse } from '@mui/material';
import { useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { usePopupContext } from '../contexts/PopupProvider';
import { auth } from '../firebase.config';
import { AccountNotification, Mail, Offers, Prospects, Refund, Upgrade } from '../helper/icons';
import { updateFirestoreDoc } from '../helper/functions';

const NotificationManager = () => {
    const { customerChats, handleMenuState, searchesContacts, updates, customer, matches } = useStateContext();
    const { setRefundInfoOpen } = usePopupContext();

    const [extended, setExtended] = useState(false);
    const [newUpdatesSeen, setNewUpdatesSeen] = useState(false);

    const hasMatches = matches.length > 0;
    const hasOffers = searchesContacts?.filter((c) => c?.status === "pending").length > 0;
    const hasMsg = customerChats?.some(c => c?.newMsg.includes(auth.currentUser.uid));
    const hasRefund = customer?.refund > 0;
    const profileMissing = !customer?.profileCompleted && !customer?.completeProfileConfirmed;

    const lastloginDay = new Date(auth.currentUser.metadata.lastSignInTime).getDate();
    const lastLoginMonth = new Date(auth.currentUser.metadata.lastSignInTime).getMonth();
    const lastLoginYear = new Date(auth.currentUser.metadata.lastSignInTime).getFullYear();

    const hasUpdate = updates?.some((u) => 
        (lastLoginYear <= new Date(u.created.toDate()).getFullYear()) && 
        (lastLoginMonth <= new Date(u.created.toDate()).getMonth()) && 
        (lastloginDay <= new Date(u.created.toDate()).getDate()) 
    );

    const handleClick = (e) => {
        e.stopPropagation();
        if(profileMissing || hasMatches || hasMsg || hasOffers || hasRefund || (hasUpdate && !newUpdatesSeen)) {
            setExtended(!extended);
        } else {
            setExtended(false);
        }
    };

    const handleNotificationClick = async (e, target) => {
        e.stopPropagation();
        setExtended(false);

        if(target === "updates") {
            setNewUpdatesSeen(true);
        } else if (target === "refund") {
            setRefundInfoOpen(true);
            return;
        } else if (target === "account") {
            await updateFirestoreDoc("customers", auth.currentUser.uid, {completeProfileConfirmed: true});
        }

        handleMenuState(target);
    };

  return (
    <div onClick={(e) => handleClick(e)} className={`fixed flex flex-col select-none ${(profileMissing || hasMatches || hasMsg || hasOffers || hasRefund || (hasUpdate && !newUpdatesSeen)) ? 'w-14 sm:w-20 cursor-pointer hover:opacity-90 active:opacity-100 z-30' : 'opacity-20 -z-10 sm:w-14 w-10'} right-1 md:right-5 bottom-1 sm:right-3 sm:bottom-3 md:bottom-5 aspect-square rounded-full bg-white flex flex-row justify-center items-center border-4 border-allimmoDark`}>
        <Collapse in={extended}>
            <div className='pt-3 mb-3'>
                {hasMatches && <Prospects onClick={(e) => handleNotificationClick(e, "objects")} className='text-allimmoDark hover:text-allimmoDark/50 mb-2' size={30}/>}
                {hasOffers && <Offers onClick={(e) => handleNotificationClick(e, "searches")} className='text-allimmoDark hover:text-allimmoDark/50 mb-2' size={30}/>}
                {hasMsg && <div><Mail onClick={(e) => handleNotificationClick(e, "chats")} className='text-allimmoDark hover:text-allimmoDark/50' size={30}/><p>{}</p></div>}
                {(hasUpdate && !newUpdatesSeen) && <Upgrade onClick={(e) => handleNotificationClick(e, "updates")} className='text-allimmoDark hover:text-allimmoDark/50' size={30}/>}
                {hasRefund && <Refund onClick={(e) => handleNotificationClick(e, "refund")} className='text-allimmoDark hover:text-allimmoDark/50' size={30}/>}
                {profileMissing && <AccountNotification onClick={(e) => handleNotificationClick(e, "account")} className='text-allimmoDark hover:text-allimmoDark/50' size={30}/>}
            </div>
        </Collapse>
        {(hasMatches || hasMsg || hasOffers || profileMissing || hasRefund || (hasUpdate && !newUpdatesSeen)) ? <img className='' width={50} src={require('../assets/bell.gif')} alt=""/> :
        <img width={50} src={require('../assets/staticBell.png')} alt=""/>}
    </div>
  )
};

export default NotificationManager
