import { convertShortTimestamp, fetchProspectInfo, hideString } from '../../helper/functions';
import { useStateContext } from '../../contexts/ContextProvider';
import { Checkbox } from '@mui/material';
import { t } from 'i18next';
import { Doorbell, Dot, NoLoan, PhoneNumberProvided, SearchOrderFace, Speed, } from '../../helper/icons';
import { useEffect, useState } from 'react';

const Prospect = ({data, select, selection, index, showSymbol, ringed}) => {

    const { screenSize } = useStateContext();

    const [prospectInfo, setProspectInfo] = useState(0);

    useEffect(() => {
      getProspectInfo();
    }, []);

    const depthTextColors = {
        3: "text-depthUngenau",
        4: "text-depthUngenau",
        5: "text-depthNormal",
        6: "text-depthNormal",
        7: "text-depthGenau",
        8: "text-depthGenau",
        9: "text-depthGenau",
        undefined: "text-gray-300",
    };

    const getProspectInfo = async () => {
        const result = await fetchProspectInfo({customerId: data.searchUser});
        setProspectInfo(result.data);
        return;
    };

    return (
        <div onClick={() => select(data.id)} className={`flex flex-row cursor-pointer hover:bg-allimmoDark/5 items-center w-full rounded border-2 active:bg-slate-50 pl-3 py-0.5 ${index !== 0 ? 'mt-1' : ''} border-black bg-white`}>
            <p className={`mr-2 sm:mr-3 text-allimmoDark`} size={18}>{index + 1}</p>
            <Dot size={22} className={`${depthTextColors[data?.searchDepth]}`}/>
                <div className={`flex flex-row items-center w-full ml-2 sm:ml-3`}>
                    <div className='flex w-24 grow flex-row gap-5'>
                        <p className='blur-[2.25px]'>{screenSize.width > 450 ? "======" : "==="}</p>
                        {showSymbol && <p className={`text-sm mr-3 hidden sm:flex ${depthTextColors[data?.searchDepth]}`}>{t(`SearchDepth.${data?.searchDepth}`)}</p>}
                    </div>
                    <div className='flex flex-row text-sm gap-1 items-center ssm:mr-2'>
                        {ringed && <Doorbell size={17} className='text-green-800 animate-pulse -ml-1'/>}
                        {data?.householdSize && !["?", ""].includes(data?.householdSize) &&
                        <p className='text-green-500 border border-dashed border-green-500 rounded-full text-xs w-4 h-4 text-center'>{data?.householdSize}</p>}
                        {/* {ringed && <Doorbell size={17} className='text-gray-200'/>} */}
                        {/* {data?.urgency !== "urgent" && <Speed size={16} className='text-gray-200'/>} */}
                        {data?.urgency === "urgent" && <Speed size={16} className='text-green-500'/>}
                        {/* {!data?.searchOrder && <SearchOrderFace size={18} className='text-gray-200'/>} */}
                        {data?.searchOrder && <SearchOrderFace size={18} className='text-green-500'/>}
                        {/* {!data?.noLoanNeeded && <NoLoan size={18} className='text-gray-200'/>} */}
                        {data?.noLoanNeeded && <NoLoan size={18} className='text-green-500'/>}
                        {/* {prospectInfo.timeoutCount <= 5 && <TimeoutCount size={18} className='text-gray-200'/>} */}
                        {/* {prospectInfo.timeoutCount > 5 && <TimeoutCount size={18} className='text-green-500'/>} */}
                        {/* {!prospectInfo.phoneNumberProvided && <PhoneNumberProvided size={17} className='text-gray-200'/>} */}
                        {/* {data?.noLoanNeeded && <NoLoan size={18} className='text-green-500'/>} */}
                        {prospectInfo.phoneNumberProvided && <PhoneNumberProvided size={17} className='text-green-500'/>}
                    </div>
                    {screenSize.width > 380 && <p className='ml-2 w-14'>{convertShortTimestamp(data?.created.toDate(), false, true)}</p>}

                    <Checkbox 
                        size='small'
                        disableRipple
                        disableTouchRipple
                        value={selection.includes(data.id)} 
                        checked={selection.includes(data.id)} 
                    />
                </div>
        </div>
    )
};

export default Prospect
