import { t } from 'i18next';
import { ConfettiTrumpet } from '../../helper/icons';

const Page5 = () => {
  return (
    <div className='flex flex-col items-center justify-center animate-fade gap-8 rounded-xl border border-gray-400 p-8 bg-white shadow-lg w-full'>
      <p className='text-center font-bold'>{t('Onboarding.Page5Header')}</p>
      
      <div className='flex flex-row items-center justify-center gap-3'>
        <div className='flex flex-row items-center justify-center p-4 border border-orange-300 rounded-xl shadow-md bg-orange-50'>
          <ConfettiTrumpet size={70} className='text-orange-300 animate-jump animate-duration-500 text-6xl'/>
        </div>
      </div>

      <p className='text-center italic text-sm'>{t('Onboarding.Page5Text')}</p>
    </div>
  )
}

export default Page5
