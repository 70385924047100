import { t } from 'i18next'
import { useStateContext } from '../../contexts/ContextProvider'
import Button from '../Generics/Button';
import { useState } from 'react';
import Prospect from './Prospect';
import { contactMatched, Transition } from '../../helper/functions';
import { Doorbell, Dot, HideText, Info, NoLoan, PhoneNumberProvided, SearchOrderFace, ShowText, Speed, TimeoutCount } from '../../helper/icons';
import { Checkbox, Collapse, Dialog, FormControlLabel } from '@mui/material';

const Matches = ({ objectData }) => {

    const { customer, handleMenuState, matches } = useStateContext();

    const [isLoading, setIsLoading] = useState(false);
    const [selection, setSelection] = useState([]);
    const [contactDisabled, setContactDisabled] = useState(false);
    const [processingMatches, setProcessingMatches] = useState([]);
    const [symbolMode, setSymbolMode] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectMax, setSelectMax] = useState(false);
    const [selectTen, setSelectTen] = useState(false);
    const [symbolInfo, setSymbolInfo] = useState("");

    //const matches = matchingSearches.filter(m => m !== "123");

    const objectMatches = matches.filter(m => m.objectId === objectData?.id);

    // useEffect(() => {
    //     const prospectsRef = query(collection(firestore, "searches"), where(documentId(), "in", [...objectData?.matches, "123"]));
    //     onSnapshot(prospectsRef, (result) => {
    //         setMatchingSearches(result.docs.map((doc) => ({...doc.data(), id: doc.id})));
    //     });
    //     // eslint-disable-next-line
    // }, [objectData]);

    const contact = async () => {
        if(selection.length > 0 && selection.length <= customer.credits) {
            setProcessingMatches([...processingMatches, ...selection]);
            setContactDisabled(true);

            setIsLoading(true);
            await contactMatched({objectId: objectData?.id, selectionArray: selection})
            setContactDisabled(false);
            setIsLoading(false);
            setProcessingMatches([]);
            setSelection([]);
        }
    };

    const handleSelection = (item) => {
        if(selection.find(el => el === item)) {
            setSelection(selection.filter(el => el !== item));
            if(selectAll) setSelectAll(false);
            if(selectMax) setSelectMax(false);
            if(selectTen) setSelectTen(false);
        } else {
            setSelection([...selection, item]);
        }
    };

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setSelection(selectAll ? [] : objectMatches.map(m => m.id));
    };

    const handleSelectMax = () => {
        setSelectMax(!selectMax);
        setSelection(selectMax ? [] : objectMatches.slice(0, customer.credits).map(m => m.id));
    };

    const handleSelectTen = () => {
        setSelectTen(!selectTen);
        setSelection(selectTen ? [] : objectMatches.slice(0, 10).map(m => m.id));
    };

  return (
    <div className={`${objectMatches.length > 0 ? "block" : "hidden"} flex flex-col items-center justify-center w-full mt-2 mb-1`}>
            <p className='text-14 grow w-full'>{`${t('ObjectDetails.InteressentenHeader')} ${objectMatches.length}`}</p>
            
            <div className='w-full flex flex-col rounded border border-allimmoDark p-1'>
                <div className='flex flex-row items-center gap-1 mmd:gap-5 w-full'>
                    <div onClick={() => setShowDescription(!showDescription)} className='cursor-pointer flex flex-row items-center gap-1.5 pl-1 hover:underline'>
                        <Info className='hover:opacity-50 text-allimmoDark' size={22}/>
                        <p className='hidden sm:block'>Erklärung</p>
                    </div>

                    {/* <div onClick={() => setSymbolMode(!symbolMode)} className='hidden sm:flex flex-row items-center gap-1.5 hover:underline cursor-pointer'>
                        {!symbolMode && <ShowText size={18} className='cursor-pointer p-0.5 hover:opacity-70 border-allimmoDark rounded-full border-2 text-allimmoDark'/>}
                        {symbolMode && <HideText size={18} className='cursor-pointer p-0.5 hover:opacity-70 border-allimmoDark rounded-full border-2 text-allimmoDark'/>}
                        <p className='hidden mmd:block'>Genauigkeit als Text</p>
                    </div> */}
                    
                    <div className='flex flex-row items-center grow justify-end gap-1'>
                        <p className='mr-5 text-base hidden mmd:block'>{t('General.Auswahl')}</p>
                        {(objectMatches.length >= 10 && customer.credits >= 10) &&
                        <FormControlLabel
                        control={
                            <Checkbox
                            disabled={selectMax || selectAll || objectMatches.length === 0 || objectMatches.length < 10 || customer.credits < 10}
                            size='small'
                            disableRipple
                            disableTouchRipple
                            value={selectTen}
                            checked={selectTen}
                            onChange={handleSelectTen}
                            />
                        }
                        label={
                            <p className={`text-sm -ml-1 ${selectMax || selectAll || objectMatches.length === 0 || objectMatches.length < 10 || customer.credits < 10 ? "opacity-50" : "text-allimmoDark"}`}>
                            {"10 Matches"}
                            </p>
                        }
                        />}

                        {(objectMatches.length > customer?.credits) &&
                        <FormControlLabel
                        control={
                            <Checkbox
                            disabled={selectAll || selectTen || objectMatches.length === 0}
                            size='small'
                            disableRipple
                            disableTouchRipple
                            value={selectMax}
                            checked={selectMax}
                            onChange={handleSelectMax}
                            />
                        }
                        label={
                            <p className={`text-sm -ml-1 ${selectAll || selectTen || objectMatches.length === 0 ? "opacity-50" : "text-allimmoDark"}`}>
                            {t("General.Max")}
                            </p>
                        }
                        />}

                        <FormControlLabel
                        control={
                            <Checkbox
                            disabled={selectMax || selectTen || objectMatches.length === 0 || objectMatches.length > customer.credits}
                            size='small'
                            disableRipple
                            disableTouchRipple
                            value={selectAll}
                            checked={selectAll}
                            onChange={handleSelectAll}
                            />
                        }
                        label={
                            <p className={`text-sm -ml-1 ${selectMax || selectTen || objectMatches.length === 0 || objectMatches.length > customer.credits ? "opacity-50" : "text-allimmoDark"}`}>
                            {t("General.Alle")}
                            </p>
                        }
                        />    
                    </div>  
                </div>
                <Collapse in={showDescription} className='flex flex-col w-full bg-allimmoDark/5 rounded'>
                    <div className='flex flex-col w-full px-2 pt-2'>
                        <p className='text-xs pl-1 italic self-start'>{t("SearchDepth.Description")}</p>
                        <div className='flex flex-row items-center gap-4'>
                            <div className='flex flex-row items-center gap-1 text-depthGenau'>
                                <Dot />
                                <p>{t("SearchDepth.7")}</p>
                            </div>

                            <div className='flex flex-row items-center gap-1 text-depthNormal'>
                                <Dot />
                                <p>{t("SearchDepth.5")}</p>
                            </div>

                            <div className='flex flex-row items-center gap-1 text-depthUngenau'>
                                <Dot />
                                <p ><p>{t("SearchDepth.4")}</p></p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col w-full px-2 pb-2'>
                        <div className='border-b border-dashed border-gray-300 w-full mt-1'/>
                        <div className='flex flex-col 550:flex-row 550:items-center 550:gap-4 mt-2'>
                            <div onClick={() => setSymbolInfo("ring")} className='flex cursor-pointer flex-row items-center gap-1 text-green-800'>
                                <Doorbell className='animate-pulse' size={17} />
                                <p className='text-sm'>hat geklingelt</p>
                            </div>

                            <div onClick={() => setSymbolInfo("speed")} className='flex cursor-pointer flex-row items-center gap-1 text-green-500'>
                                <Speed size={17} />
                                <p className='text-sm'>hat es eilig</p>
                            </div>

                            <div onClick={() => setSymbolInfo("order")} className='flex flex-row items-center cursor-pointer gap-1 text-green-500'>
                                <SearchOrderFace size={17} />
                                <p className='text-sm'>sucht im Auftrag eine Dritten</p>
                            </div>
                        </div>

                        <div className='flex flex-col 650:flex-row 650:items-center 650:gap-4'>
                            <div onClick={() => setSymbolInfo("noLoan")} className='cursor-pointer flex flex-row items-center gap-1 text-green-500'>
                                <NoLoan size={17} />
                                <p className='text-sm'>keine Finanzierung nötig</p>
                            </div>

                            {/* <div onClick={() => setSymbolInfo("timeout")} className='cursor-pointer flex flex-row items-center gap-1 text-green-500'>
                                <TimeoutCount size={17} />
                                <p className='text-sm'>nicht sehr aktiv</p>
                            </div> */}

                            <div onClick={() => setSymbolInfo("housholdSize")} className='cursor-pointer flex flex-row items-center gap-1 text-green-500'>
                                <p className='text-green-500 border border-dashed border-green-500 rounded-full text-xs w-4 h-4 text-center'>3</p>
                                <p className='text-sm'>gesuchte Haushaltsgröße</p>
                            </div>

                            <div onClick={() => setSymbolInfo("phone")} className='cursor-pointer flex flex-row items-center gap-1 text-green-500'>
                                <PhoneNumberProvided size={17} />
                                <p className='text-sm'>Telefonnummer angegeben</p>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            
            <div className='flex flex-col items-center w-full border rounded border-allimmoDark max-h-64 overflow-auto p-1 mt-1'>
                {objectMatches.filter(m => !processingMatches.includes(m)).sort((a,b) => a?.created.toDate() - b?.created.toDate())
                // .filter((obj, index, self) =>
                //     index === self.findIndex(o => o.searchId === obj.searchId)
                // ) // filter out duplicates
                .map((p, i) => {
                return (
                    <Prospect 
                        key={i} 
                        data={p} 
                        select={handleSelection}
                        selection={selection} 
                        index={i} 
                        showSymbol={symbolMode}
                        ringed={objectData?.rings && objectData?.rings.includes(p.id)}
                    />
                )
                })}
                 
            </div>
            <p className='italic mt-1 text-xs w-full text-allimmoDark/70'>{t('ObjectDetails.CreditsInfo')}</p>
            <div className='flex flex-col ssm:flex-row ssm:items-center w-full mt-1'>
                <Button loading={isLoading} isDisabled={selection.length === 0 || selection.length > customer.credits || contactDisabled} clickFunction={contact} text={`${t('ObjectDetails.Kontaktieren')} (${selection.length})`}/>
                <div className='flex flex-row ssm:justify-end items-center grow'>
                    <p className='text-allimmoDark mr-1'>{`${t('ObjectDetails.Kosten')}:`}</p>
                    <p className='text-allimmoDark'>{`${selection.length}`}</p>
                    <p className='text-allimmoDark'>{`/`}</p>
                    <p className={`text-allimmoDark mr-1`}>{`${customer.credits.toLocaleString()} Credits`}</p>
                    {customer?.credits <= 2 && <img onClick={() => handleMenuState("shop")} width={30} src={require('../../assets/cart.gif')} alt=""/>}
                </div>
            </div>
        <Dialog open={symbolInfo !== ""} fullWidth={false} fullScreen={false} TransitionComponent={Transition}>
                <div className='px-3 py-2 flex flex-col items-center justify-center max-w-500'>
                    {symbolInfo === "ring" && <Doorbell className='animate-pulse' size={24} />}
                    {symbolInfo === "speed" && <Speed size={24} />}
                    {symbolInfo === "order" && <SearchOrderFace size={24} />}
                    {symbolInfo === "noLoan" && <NoLoan size={24} />}
                    {symbolInfo === "timeout" && <TimeoutCount size={24} />}
                    {symbolInfo === "phone" && <PhoneNumberProvided size={24} />}
                    <p className='text-allimmoDark w-full text-center px-3 text-lg mb-2'>
                        {symbolInfo === "ring" && "Hat geklingelt"}
                        {symbolInfo === "speed" && "Hat es eilig"}
                        {symbolInfo === "order" && "Sucht im Auftrag eines Dritten"}
                        {symbolInfo === "noLoan" && "Keine Finanzierung nötig"}
                        {symbolInfo === "timeout" && "Nicht sehr aktiv"}
                        {symbolInfo === "phone" && "Telefonnummer angegeben"}
                    </p>
                    <p className='w-full text-center px-5 italic text-sm'>
                        {symbolInfo === "ring" && "Dies bedeutet dass der Interessent über die mobile App bei diesem Objekt nachdrücklich Interesse bekundet hat und eine schnelle Kontaktaufnahme wünscht."}
                        {symbolInfo === "speed" && "Dies bedeutet dass der Interessent angegeben hat dass er es mit der Immobiliensuche sehr eilig hat und eine schnelle Kontaktaufnahme wünscht."}
                        {symbolInfo === "order" && "Der Interessent sucht für einen Dritten nach einer Immobilie und ist nicht selbst der Endkunde."}
                        {symbolInfo === "noLoan" && "Dies beduetet dass der Interessent angibt keine Finanzierung für den Kauf der Immobilie zu benötigen."}
                        {symbolInfo === "timeout" && "Der Interessent ist nicht sehr aktiv und hat in letzter Zeit einige Anfragen ablaufen lassen."}
                        {symbolInfo === "phone" && "Der Interessent hat in seinen Kontaktdaten eine Telefonnummer angegeben."}
                    </p>
                    <p className='cursor-pointer hover:underline mt-3 text-allimmoDark text-lg font-semibold' onClick={() => setSymbolInfo("")}>OK</p>
                </div>
        </Dialog>
    </div>
  )
}

export default Matches
