import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useParams } from "react-router-dom";
import { Checkmark, ErrorMessage, House } from "../helper/icons";
import { sendNewReport } from "../helper/functions";
import { t } from "i18next";

const MatchAccept = () => {
    const { user } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [result, setResult] = useState("");
    const [reportSent, setReportSent] = useState(false);
    const [sending, setSending] = useState(false);
    const [resultData, setResultData] = useState({});
    const [reportMessage, setReportMessage] = useState("");

    useEffect(() => {
        acceptMatch();
    }, []);

    const acceptMatch = async () => {
        if(["user", ""].includes(user)) {
            setIsLoading(false);
            return setResult("user-not-found");
        }
        const response = await fetch("https://europe-west3-move-58c8d.cloudfunctions.net/acceptPendingContacts", {
        //const response = await fetch("http://127.0.0.1:5001/move-58c8d/europe-west3/acceptPendingContacts", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                customerId: user,
            }),
        });
        const data = await response.json();
        setResult(data);
        setIsLoading(false);
    };

    const sendReport = async () => {
        setSending(true);
        const report = await sendNewReport({subject: "matchesAccept Error", sender: user, coll: "contacts"});
        console.log(report.data);
        if(report.data.result === "already-sent") {
            setReportMessage(t('OneClickMatchAccept.ErrorAlreadySent'));
        } else if (report.data.result === "success") {
            setReportMessage(t('OneClickMatchAccept.ErrorSent'));
        }
        setResultData(report.data);
        setReportSent(true);
        setSending(false);
        return;
    };
    
    return (
        <div className="flex flex-col items-center mt-20">
            {isLoading && 
            <div className="flex flex-col items-center justify-center gap-1">
                <CircularProgress size={60} />
                <p className="text-lg text-allimmoDark">{t('OneClickMatchAccept.Verarbeitung')}</p>
            </div>}
            {!isLoading &&
            <div className="flex flex-col items-center justify-center">
                <img className="pb-8" width={250} src={'https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2FAllimmomatch.png?alt=media&token=e6afb35b-6fb7-46dd-831f-4dd1b233b598'} alt='ALLIMMO'/>
                {result.message === "success" && <ConfettiExplosion />}
                {result.message === "success" && <p className="text-green-400 text-xl font-bold bg-green-100">{`${result.count} ${t(`OneClickMatchAccept.Success`)}`}</p>}
                {result.message === "success" && <p className="text-sm italic w-500 text-center mt-1">{t(`OneClickMatchAccept.SuccessText`)}</p>}
                {result.message !== "success" && <p className="text-red-400 text-lg font-bold bg-red-100">{t(`OneClickMatchAccept.Errors.${result}`)}</p>}
                {result.message !== "success" && <p className="text-sm italic w-500 text-center mt-1">{t(`OneClickMatchAccept.ErrorText`)}</p>}
                {/* <p className="mt-5">User: {user}</p>
                <p>Contact: {contact}</p> */}
                
                <div className="flex flex-row items-center justify-center mt-8 gap-2">
                    {(result.message !== "success") &&
                    <div onClick={reportSent ? null : sendReport} className={`flex h-8 w-180 flex-row gap-3 items-center justify-center ${reportSent ? "text-green-500 border border-green-500" : "text-red-500 border border-red-500 cursor-pointer hover:opacity-60"} bg-white shadow-md`}>
                        {!reportSent && <ErrorMessage size={16}/>}
                        {!reportSent && <p>{t('OneClickMatchAccept.FehlerMelden')}</p>}
                        {sending && <CircularProgress size={16} />}
                        {reportSent && <Checkmark className="text-green-500" size={20} />}
                    </div>}

                    <div onClick={() => window.open("https://www.allimmomatch.com", "_self")} className="flex h-8 w-180 flex-row gap-3 items-center justify-center cursor-pointer hover:opacity-60 text-allimmoDark border border-allimmoDark bg-white shadow-md">
                        {<House size={16} />}
                        <p>{t('OneClickMatchAccept.ZurHomepage')}</p>
                    </div>
                </div>

                <p className="font-bold mt-5 text-lg">{reportMessage}</p>
                {resultData?.reportId && <p className="mt-1 underline">Support Ticket:</p>}
                <p className="italic select-all -mt-0.5">{resultData?.reportId}</p>
                {resultData?.status && <p className="mt-1 italic">Status: {t(`Reports.Status.${resultData?.status}`) }</p>}
            </div>}
        </div>
    )
}

export default MatchAccept
