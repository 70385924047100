import { t } from 'i18next';
import { HouseFill, Searches } from '../../helper/icons';

const Page1 = () => {
  return (
    <div className='flex flex-col items-center justify-center animate-fade gap-8 rounded-xl border border-gray-400 py-8 bg-white shadow-lg w-full'>
      <p className='text-center font-bold'>{t('Onboarding.Page1Header')}</p>
      
      <div className='flex flex-row items-center justify-center gap-6'>
        <div className='flex flex-row items-center justify-center p-4 border border-orange-300 rounded-xl shadow-md bg-orange-50'>
          <Searches size={70} className='text-orange-300 text-6xl'/>
        </div>
        
      </div>

      <p className='text-center italic text-sm px-6'>{t('Onboarding.Page1Text')}</p>
    </div>
  )
}

export default Page1
