import { t } from 'i18next';
import { Bell, Mail, SMSMessage } from '../../helper/icons';
import { useStateContext } from '../../contexts/ContextProvider';

const Page3 = () => {

  const { screenSize } = useStateContext();

  return (
    <div className='flex flex-col items-center justify-center animate-fade gap-8 rounded-xl border border-gray-400 p-6 ssm:p-8 bg-white shadow-lg w-full'>
      <p className='text-center font-bold'>{t('Onboarding.Page3Header')}</p>
      
      <div className='flex flex-row items-center justify-center gap-1.5 ssm:gap-3'>
        <div className='animate-fade animate-delay-300 flex flex-row items-center justify-center p-4 border border-orange-300 rounded-xl shadow-md bg-orange-50'>
          <Bell size={screenSize.width > 500 ? 70 : 50} className='text-orange-300 text-6xl'/>
        </div>

        <div className='animate-fade animate-delay-[600ms] flex flex-row items-center justify-center p-4 border border-orange-300 rounded-xl shadow-md bg-orange-50'>
          <Mail size={screenSize.width > 500 ? 70 : 50} className='text-orange-300 text-6xl'/>
        </div>

        <div className='animate-fade animate-delay-[900ms] flex flex-row items-center justify-center p-4 border border-orange-300 rounded-xl shadow-md bg-orange-50'>
          <SMSMessage size={screenSize.width > 500 ? 70 : 50} className='text-orange-300 text-6xl'/>
        </div>
      </div>

      <p className='text-center italic text-sm'>{t('Onboarding.Page3Text')}</p>
    </div>
  )
}

export default Page3
