import { CircularProgress, Dialog, MenuItem, TextField } from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import { collection, getDocsFromServer, query, where } from 'firebase/firestore';
import i18next, { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import Button from '../components/Generics/Button';
import { auth, firestore } from '../firebase.config';
import { Transition, convertShortTimestamp, updateFirestoreDoc, updateSingleProfileData } from '../helper/functions';
import { useStateContext } from '../contexts/ContextProvider';
import { ChangeCircle, Check, Checkmark, CloseX, Connected, Delete, Devices, Disconnected, MobilePhone, Payments } from '../helper/icons.js';
import validator from 'validator';
import useOutsideClick from '../hooks/useOutsideClick.jsx';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import de from 'i18n-iso-countries/langs/de.json';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import dePhone from 'react-phone-input-2/lang/de.json';
import i18n from '../i18n.js';

const Account = () => {

  const { customer, changeLanguage } = useStateContext();

  const [paymentsOpen, setPaymentsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [payments, setPayments] = useState([]);
  const [genderLoading, setGenderLoading] = useState(false);
  const [incomeLoading, setIncomeLoading] = useState(false);
  const [educationLoading, setEducationLoading] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [employmentLoading, setEmploymentLoading] = useState(false);
  const [yearLoading, setYearLoading] = useState(false);
  const [zipLoading, setZipLoading] = useState(false);
  const [yearEditMode, setYearEditMode] = useState(false);
  const [zipEditMode, setZipEditMode] = useState(false);
  const [newYear, setNewYear] = useState(customer?.yearOfBirth ?? "-");
  const [newZip, setNewZip] = useState(customer?.presentZip ?? "-");
  const [showDevices, setShowDevices] = useState(false);
  const [newPhone, setNewPhone] = useState(customer?.phone ?? "");

  useEffect(() => {
    if(emailSent) {
      setTimeout(() => {
        setEmailSent(false);
      }, "5000");
    }
    // eslint-disable-next-line
  }, [emailSent]);

  // useEffect(() => {
  //   console.log("newPhone", newPhone)
  //   console.log("Customer", customer?.phone)
    
  // }, [newPhone])

  const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => {
      setYearEditMode(false);
      setPaymentsOpen(false);
      setShowDevices(false);
  });

  const lang = i18next.resolvedLanguage === "de" ? "de" : "en";
  const noSpacePhone = String(newPhone).replaceAll(" ", "");
    
  countries.registerLocale(de);
  countries.registerLocale(en);

  const countryList = Object.keys(countries.getAlpha2Codes()).map((code) => ({
    code,
    name: countries.getName(code, lang),
    flag: `https://flagcdn.com/w320/${code.toLowerCase()}.png`,
  }));

  const getPayments = async () => {
    setPaymentsOpen(true);
    setIsLoading(true);
    const paymentsQuery = query(collection(firestore, "payments"), where("client_reference_id", "==", auth.currentUser.uid));
    const querySnapshot = await getDocsFromServer(paymentsQuery);
    setPayments(querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id })));
    setIsLoading(false);
  };

  const changeGender = async () => {
    setGenderLoading(true);
    const genderOptions = [
      "male",
      "female",
      "divers",
      "-"
    ];

    const currentGender = customer?.gender ?? "-";
    const currentIndex = genderOptions?.indexOf(currentGender);
    const newGender = genderOptions[currentIndex === genderOptions.length - 1 ? 0 : currentIndex + 1];
    
    try {
      await updateSingleProfileData({updateData: {gender: newGender}});
      setGenderLoading(false);
      return;
    } catch (error) {
      console.error("Error updating document: ", error.message);
      setGenderLoading(false);
      return;
    }
  };

  const changeIncome = async () => {
    setIncomeLoading(false);
    const incomeOptions = [
      "1m",
      "13",
      "35",
      "5p",
      "-"
    ];

    const currentIncome = customer?.income ?? "-";
    const currentIndex = incomeOptions?.indexOf(currentIncome);
    
    const newIncome = incomeOptions[currentIndex === incomeOptions.length - 1 ? 0 : currentIndex + 1];
    
    try {
      await updateSingleProfileData({updateData: {income: newIncome}});
      setIncomeLoading(false);
      return true;
    } catch (error) {
      console.error("Error updating document: ", error.message);
      setIncomeLoading(false);
      return false;
    }
  };

  const changeEducation = async () => {
    setEducationLoading(false);
    const educationOptions = [
      "degree",
      "matura",
      "lehre",
      "special",
      "-"
    ];

    const currentEducation = customer?.education ?? "-";
    const currentIndex = educationOptions?.indexOf(currentEducation);
    
    const newEducation = educationOptions[currentIndex === educationOptions.length - 1 ? 0 : currentIndex + 1];
    
    try {
      await updateSingleProfileData({updateData: {education: newEducation}});
      setEducationLoading(false);
      return true;
    } catch (error) {
      console.error("Error updating document: ", error.message);
      setEducationLoading(false);
      return false;
    }
  };

  const changeEmployment = async () => {
    setEmploymentLoading(false);
    const employmentOptions = [
      "employed",
      "self",
      "retired",
      "training",
      "-"
    ];

    const currentEmployment = customer?.employment ?? "-";
    const currentIndex = employmentOptions?.indexOf(currentEmployment);
    
    const newEmployment = employmentOptions[currentIndex === employmentOptions.length - 1 ? 0 : currentIndex + 1];
    
    try {
      await updateSingleProfileData({updateData: {employment: newEmployment}});
      setEmploymentLoading(false);
      return true;
    } catch (error) {
      console.error("Error updating document: ", error.message);
      setEmploymentLoading(false);
      return false;
    }
  };

  const handleYearEnterPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      changeYear(event, true);
    }
  };

  const handleZipEnterPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      changeZip(event, true);
    }
  };

  const handlePhoneEnterPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      changePhone(event, true);
    }
  };

  const changePhone = async (e, isEnterPress = false) => {
    if(!isEnterPress) e.stopPropagation();

    setPhoneLoading(true);

    try {
      await updateSingleProfileData({updateData: {phone: newPhone.length < 3 ? "" : noSpacePhone}});
      setPhoneLoading(false);
      return "success";
    } catch (error) {
      setPhoneLoading(false);
      console.error("Error updating document: ", error.message);
      return "error";
    }
  }; 

  const changeYear = async (e, isEnterPress = false) => {
    if(!isEnterPress) e.stopPropagation();
    if(!validator.isNumeric(newYear) || newYear.length != 4) return "invalid";
    
    setYearEditMode(false);
    setYearLoading(true);
    try {
      await updateSingleProfileData({updateData: {yearOfBirth: newYear}});
      setYearLoading(false);
      return "success";
    } catch (error) {
      setYearLoading(false);
      setYearEditMode(true);
      console.error("Error updating document: ", error.message);
      return "error";
    }
  };

  const changeZip = async (e, isEnterPress = false) => {
    if(!isEnterPress) e.stopPropagation();
    if(!validator.isNumeric(newZip) || (newZip.length != 4 && newZip.length != 5)) return "invalid";
    
    setZipEditMode(false);
    setZipLoading(true);
    try {
      await updateSingleProfileData({updateData: {presentZip: newZip}});
      setZipLoading(false);
      return "success";
    } catch (error) {
      setZipLoading(false);
      setZipEditMode(true);
      console.error("Error updating document: ", error.message);
      return "error";
    }
  };

  const activationChange = async (deviceID) => {
    const updatedDevice = customer?.devices.find(d => d.deviceID === deviceID);
    if(!updatedDevice) return;
    updatedDevice.active = !updatedDevice.active;
    const updatedDevices = customer?.devices.filter(d => d.deviceID !== deviceID);
    updatedDevices.push(updatedDevice);
    await updateFirestoreDoc("customers", auth.currentUser.uid, {devices: updatedDevices});
    
    return;
  };

  const sendPasswordReset = () => {
    setIsResetLoading(true);
    sendPasswordResetEmail(auth, auth.currentUser.email)
    .then(() => {
      setEmailSent(true);
      setIsResetLoading(false);
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });
  };

  const changeAccounType = async () => {
    await updateFirestoreDoc("customers", auth.currentUser.uid, {company: !customer?.company});
    return;
  };

  const changeCountry = async (newCountry) => {
    try {
      await updateSingleProfileData({updateData: {country: newCountry}});
      setYearLoading(false);
      return "success";
    } catch (error) {
      setYearLoading(false);
      setYearEditMode(true);
      console.error("Error updating document: ", error.message);
      return "error";
    }
  };

  return (
    <div className={`flex flex-col items-center w-screen mt-4`}>
        <div className='flex flex-row items-center justify-center border border-allimmoDark/20 rounded w-308 bg-white shadow-lg py-2 px-3'>
          {/* <div className={`border-allimmoDark rounded-full h-22 aspect-square flex flex-row justify-center items-center`}>
              <AnimationIcon _iconSize={80} _color={false} iconSrc={require('../assets/animations/avatar.json')}/>
          </div> */}
          <div className='flex flex-col justify-center items-center'>
            <p className='text-2xl text-allimmoDark'>{customer?.name.trim() === "" ? t('Account.KeinUsername') : customer?.name}</p>
            <div className='flex flex-row gap-1.5 items-center'>
              <p className='text-14 italic'>{customer?.company ? t("Kontotypen.Firma") : t("Kontotypen.Privat")}</p>
              <ChangeCircle onClick={changeAccounType} className='cursor-pointer hover:text-green-500 opacity-50 hover:opacity-100' size={17} />
            </div>
            <p className='text-lg italic cursor-pointer'>{auth.currentUser.email}</p>
            <p className='text-sm'>{`${t('General.erstellt')} am ${convertShortTimestamp(auth.currentUser.metadata.creationTime)}`}</p>
            <p className='italic text-11 select-text text-slate-400'>{`id: ${auth.currentUser.uid}`}</p>
          </div>
        </div>

        {/* Language Selector */}
        <div className={`flex flex-row items-center px-2 gap-1 mt-1 w-308 h-10 shadow-lg rounded border border-allimmoDark/20 bg-white`}>
          <p className='text-allimmoDark text-sm grow'>{t('Account.Sprache')}</p>
          <img onClick={() => changeLanguage("de")} className={`rounded-lg border-2 cursor-pointer ${i18n.resolvedLanguage === "en" ? "opacity-20" : ""} hover:opacity-100 border-black`} src={require('../assets/ger.png')} alt="Germany" width={22}/>
          <img onClick={() => changeLanguage("en")} className={`rounded-lg border-2 cursor-pointer ${i18n.resolvedLanguage === "de" ? "opacity-20" : ""} hover:opacity-100 border-black`} src={require('../assets/eng.png')} alt="English" width={22}/>
        </div>

        {(auth.currentUser && !customer?.profileCompleted) && 
        <div className={`flex flex-col justify-center items-center mt-1 w-308 shadow-lg rounded border bg-orange-50 text-orange-400 border-orange-400 py-1 px-1`}>
          <p className='text-center text-sm'>{t('Account.CompleteProfileInfo')}</p>
        </div>}
        <div className={`flex flex-row items-center mt-1 w-308 shadow-lg rounded border border-allimmoDark/20 py-1 bg-white pl-1 ${customer?.phone === noSpacePhone ? "pr-1" : "pr-0"}`}>
          <PhoneInput
            preferredCountries={['at', 'de', 'ch']}
            autoFormat={false}
            onKeyDown={(e) => handlePhoneEnterPress(e)}
            countryCodeEditable={false}
            disabled={phoneLoading}
            inputStyle={{width: '100%', backgroundColor: [undefined, "", "-"].includes(customer?.phone) ? 'rgb(255, 235, 238)' : 'white'}}
            country='at'
            value={newPhone}
            localization={lang === "de" ? dePhone : undefined}
            onChange={phone => setNewPhone("+" + phone)}
            inputProps={{
              required: true
            }}
          />
          {customer?.phone !== newPhone &&
          <div className='flex flex-row items-center justify-center h-full px-2'>
            {phoneLoading && <CircularProgress size={18} />}
            {!phoneLoading && <Checkmark onClick={changePhone}  className='cursor-pointer hover:text-green-500 opacity-50 hover:opacity-100' size={17} />}
          </div>}
        </div>

        <div className={`flex mt-1 flex-col smd:flex-row gap-1 text-allimmoDark justify-center cursor-pointer items-center`}>
            <div onClick={!genderLoading ? changeGender : null} className={`rounded h-16 w-308 smd:w-151 flex flex-col justify-center cursor-pointer hover:border-allimmoDark items-center shadow-lg border border-allimmoDark/20 px-2 py-1 ${genderLoading ? "opacity-50" : ""} ${[undefined, "", "-"].includes(customer?.gender) ? "bg-red-50" : "bg-white"}`}>
              <p className='text-xs font-bold -mb-1 opacity-70'>{t('Account.Geschlecht')}</p>
              {!genderLoading && <p className='text-lg font-thin'>{t(`Gender.${customer?.gender}`)}</p>}
              {genderLoading && <CircularProgress className='mt-2' size={14} />}
            </div>

            <div ref={wrapperRef} onClick={() => setYearEditMode(true)} className={`rounded h-16 text-allimmoDark w-308 smd:w-151 flex flex-col justify-center cursor-pointer hover:border-allimmoDark items-center shadow-lg border border-allimmoDark/20 px-2 py-1 ${[undefined, "", "-"].includes(customer?.yearOfBirth) ? "bg-red-50" : "bg-white"}`}>
              <p className='text-xs font-bold -mb-1 opacity-70'>{t('Account.Geburtsjahr')}</p>
              {(!yearEditMode && !yearLoading) && <p className='text-lg font-thin'>{customer?.yearOfBirth ?? newYear}</p>}
              {(yearEditMode && !yearLoading) &&
              <div className='flex flex-row items-center gap-1 mt-1'>
                <input onKeyDown={(e) => handleYearEnterPress(e)} autoFocus className='w-full grow text-center' type="number" value={newYear} onChange={(e) => setNewYear(e.target.value)}/>
                {(newYear.length === 4 && validator.isNumeric(newYear) && (newYear !== customer?.yearOfBirth)) && <Check onClick={(e) => changeYear(e)} className='text-green-600 font-bold cursor-pointer' size={18} />}
              </div>}
              {yearLoading && <CircularProgress className='mt-2' size={14} />}
            </div>
        </div>

        <div className='mt-1 flex flex-col smd:flex-row gap-1 justify-center cursor-pointer items-center'>
            <div onClick={!employmentLoading ? changeEmployment : null} className={`rounded text-allimmoDark w-308 smd:w-151 h-16 flex flex-col justify-center hover:border-allimmoDark items-center shadow-lg border border-allimmoDark/20 px-2 py-1 ${[undefined, "", "-"].includes(customer?.employment) ? "bg-red-50" : "bg-white"}`}>
              <p className='text-xs font-bold -mb-1 opacity-70'>{t('Account.Arbeitsverhältnis')}</p>
              {!employmentLoading && <p className='text-lg font-thin'>{t(`Arbeitsverhältnis.${customer?.employment}`)}</p>}
              {employmentLoading && <CircularProgress className='mt-2' size={14} />}
            </div>

            <div onClick={!incomeLoading ? changeIncome : null} className={`rounded w-308 smd:w-150 h-16 flex flex-col justify-center cursor-pointer hover:border-allimmoDark text-allimmoDark items-center shadow-lg border border-allimmoDark/20 px-2 py-1 ${[undefined, "", "-"].includes(customer?.income) ? "bg-red-50" : "bg-white"}`}>
              <p className='text-xs font-bold -mb-1 opacity-70'>{t('Account.Nettoeinkommen')}</p>
              {!incomeLoading && <p className='text-lg font-thin'>{t(`Income.${customer?.income}`)}</p>}
              {incomeLoading && <CircularProgress className='mt-2' size={14} />}
            </div>
        </div>

        <div className='my-1 flex flex-col smd:flex-row gap-1 justify-center cursor-pointer items-center'>
            <div onClick={!educationLoading ? changeEducation : null} className={`rounded text-allimmoDark w-308 smd:w-151 h-16 flex flex-col justify-center hover:border-allimmoDark items-center shadow-lg border border-allimmoDark/20 px-2 py-1 ${[undefined, "", "-"].includes(customer?.education) ? "bg-red-50" : "bg-white"}`}>
              <p className='text-xs font-bold -mb-1 opacity-70'>{t('Account.Ausbildung')}</p>
              {!educationLoading && <p className='text-lg font-thin'>{t(`Ausbildung.${customer?.education}`)}</p>}
              {educationLoading && <CircularProgress className='mt-2' size={14} />}
            </div>

            <div ref={wrapperRef} onClick={() => setZipEditMode(true)} className={`rounded h-16 text-allimmoDark w-308 smd:w-151 flex flex-col justify-center cursor-pointer hover:border-allimmoDark items-center shadow-lg border border-allimmoDark/20 px-2 py-1 ${[undefined, "", "-"].includes(customer?.presentZip) ? "bg-red-50" : "bg-white"}`}>
              <p className='text-xs font-bold -mb-1 opacity-70'>{t('Account.PresentZip')}</p>
              {(!zipEditMode && !zipLoading) && <p className='text-lg font-thin'>{customer?.presentZip ?? newZip}</p>}
              {(zipEditMode && !zipLoading) &&
              <div className='flex flex-row items-center gap-1 mt-1'>
                <input onKeyDown={(e) => handleZipEnterPress(e)} autoFocus className='w-full grow text-center' type="number" value={newZip} onChange={(e) => setNewZip(e.target.value)}/>
                {((newZip.length === 4 || newZip.length === 5) && validator.isNumeric(newZip) && (newZip !== customer?.presentZip)) && <Check onClick={(e) => changeZip(e)} className='text-green-600 font-bold cursor-pointer' size={18} />}
              </div>}
              {zipLoading && <CircularProgress className='mt-2' size={14} />}
            </div>
        </div>

        <div className='rounded w-308 h-16 flex flex-col justify-center cursor-pointer hover:border-allimmoDark text-allimmoDark items-center bg-white shadow-lg border border-allimmoDark/20 px-1 py-1 gap-0.5'>
              <p className='text-xs w-full text-start font-bold -mb-0.5 opacity-70'>{t('CompleteProfile.Herkunftsland')}</p>
              <TextField variant='standard' InputProps={{disableUnderline: true}} autoFocus={false} sx={{bgcolor: 'white'}} value={customer?.country} onChange={(e) => changeCountry(e.target.value)} className='w-full outline-none' select size='small' >
                {countryList.sort((a,b) => a?.name.localeCompare(b?.name)).map((country, index) => {
                  return (
                    <MenuItem key={index} value={country.code}>
                      <div className='flex flex-row items-center gap-2'>
                        <img src={country.flag} alt={country.name} className='w-6 h-5 border border-black rounded'/>
                        <p>{country.name}</p>
                      </div>
                    </MenuItem>
                  )
                })}
              </TextField>
            </div>

        <p className='italic text-allimmoDark text-xs mt-1'>{t('Account.ÄndernKlicken')}</p>

        <div className='flex flex-col ssm:flex-row justify-center items-center gap-2 mt-6'>
            <div onClick={getPayments} className='flex cursor-pointer w-180 hover:bg-gray-100 flex-row justify-center items-center gap-2 rounded border shadow-lg bg-white border-allimmoDark px-3 py-2'>
                <Payments className='text-allimmoDark' size={20} />
                <p className='text-allimmoDark font-thin text-sm'>{t('Account.ZahlungenAnsehen')}</p>
            </div>

            {customer?.devices &&
            <div onClick={() => setShowDevices(true)} className='flex cursor-pointer w-180 hover:bg-gray-100 flex-row justify-center items-center gap-2 rounded border shadow-lg bg-white border-allimmoDark px-3 py-2'>
                <Devices className='text-allimmoDark' size={20} />
                <p className='text-allimmoDark font-thin text-sm'>{t('Account.DevicesAnsehen')}</p>
            </div>}
            {/* {auth.currentUser.providerData[0].providerId !== "password" && <Button text={"TEXT"} clickFunction={sendPasswordReset} loading={isResetLoading || emailSent}/>} */}
        </div>

        <p className='text-allimmoDark italic text-sm text-center px-2 sm:px-5 mt-3 mb-5'>{t('Account.Löschen')}</p>

        {(emailSent && !isResetLoading) &&
        <div className='flex flex-col justify-center items-center'>
          <p className='text-green-500 text-14 mt-5'>{t('Account.ResetEmailSent')}</p>
          <p className='text-green-500 text-14'>{auth.currentUser.email}</p>
        </div>}

        <Dialog open={paymentsOpen} fullWidth={true} TransitionComponent={Transition}>
          <div ref={wrapperRef} className='p-2 ssm:p-5 flex flex-col justify-center items-center'>
            <p className='mb-5 text-lg ssm:text-xl'>{t('Account.MeineZahlungen')}</p>
            {isLoading ? <CircularProgress size={50} /> :
            payments.length === 0 ? 
            <p className='mb-5'>{t('Account.KeineZahlungen')}</p> :
            payments.map((p,i) => {
              return (
                <div key={i} className='flex flex-row items-center bg-slate-50 px-2 py-0.5 w-full space-x-3 ssm:space-x-5 text-xs ssm:text-base'>
                  <p>{i + 1}.</p>
                  <p className='grow'>{`${(p.amount_total/100).toFixed(2)}€ (+${p.credits} Credits)`}</p>
                  <p className='text-end'>{new Date(p.created * 1000).toLocaleString()}</p>
                </div>
              )
            })}

            <div className='mt-5'>
              <Button text="OK" clickFunction={() => setPaymentsOpen(false)}/>
            </div>
          </div>
        </Dialog>

        <Dialog open={showDevices} fullWidth={true} TransitionComponent={Transition}>
          <div ref={wrapperRef} className='p-2 ssm:p-5 flex flex-col justify-center items-center'>
            <div className='w-full flex flex-row justify-end'>
                <CloseX className='cursor-pointer hover:opacity-50' onClick={() => setShowDevices(false)}/>
              </div>
              <p className='text-lg ssm:text-xl'>{t('Account.Devices')}</p>
              <p className='mb-5 text-xs italic text-allimmoDark text-center'>Hier siehst Du alle Geräte, die mit der Allimmo Match ToGo App verknüpft sind oder es in der Vergangenheit waren. Einfach mit einen klick Verbindung aufheben oder wiederherstellen.</p>
              <div className='flex flex-col items-center justify-center gap-1 w-full'>
                {customer?.devices?.sort((a,b) => b.active - a.active).map((d,i) => {
                  return (
                    <div onClick={() => activationChange(d.deviceID)} className={`flex cursor-pointer hover:bg-allimmoDark/5 flex-row items-center rounded border ${d.active ? "border-solid opacity-100" : "border-dashed opacity-40"} border-allimmoDark p-2 w-full gap-2`} key={i}>
                      {d.data.type === "expo" && <MobilePhone className='' size={20} />}
                      <p className='text-sm italic text-allimmoDark grow'>{d.deviceName}</p>
                      {d.active && <p className='text-allimmoDark text-xs italic mr-2'>{t("General.Verbunden")}</p>}
                      {d.active && <Connected  className='text-green-500' size={18} />}
                      {!d.active && <Disconnected className='text-red-500' size={18} />}
                    </div>
                  )
                })}
            </div>
          </div>
        </Dialog>
    </div>
  )
};

export default Account
