import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { auth, firestore } from '../firebase.config';

const Leads = () => {

  const [leads, setLeads] = useState([]);

  useEffect(() => {
    const leadsQuery = query(collection(firestore, "leads"), where("customer", "==", auth.currentUser.uid));
    const unsubLeads = onSnapshot(leadsQuery, (result) => {
      setLeads(result.docs.map((doc) => ({...doc.data(), id: doc.id })));
    });

    return () => unsubLeads();
  }, []);

  return (
    <div className='flex flex-col gap-1 w-full px-2 ssm:px-0 ssm:w-2/3 ssm:mt-8'>
      <p className='text-bold text-allimmoDark text-lg mb-6'>Kunden Management</p>
      {leads.map((l, i) => {
        return (
          <div key={i} className='flex bg-white shadow cursor-pointer hover:bg-allimmoDark/5 w-full flex-col py-2 px-3 border border-orange-400 rounded'>
            <div className='flex flex-row justify-between items-center grow'>
              <p>{l.name}</p>
              <p>{l?.email}</p>
              
            </div>
          </div>
        )
      })}
      </div>
  )
}

export default Leads
