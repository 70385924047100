import React from 'react'
import { useStateContext } from '../../contexts/ContextProvider'
import StatsItem from './StatsItem';
import { useSearchesContext } from '../../contexts/SearchesProvider';
import { auth } from '../../firebase.config';
import { FaSearch } from 'react-icons/fa';
import CountUp from 'react-countup';

const SearchesStats = () => {
    const { searchesContacts } = useStateContext();
    const { customerSearches } = useSearchesContext();

    const activeSearches = customerSearches.filter((search) => search?.active).length;
    const timeoutContacts = searchesContacts.filter((contact) => ["timeout"].includes(contact.status)).length;
    const deletedContacts = searchesContacts.filter((contact) => ["search-deleted", "object-deleted"].includes(contact.status)).length;
    const pendingContacts = searchesContacts.filter((contact) => contact.status === "pending").length;
    const acceptedContacts = searchesContacts.filter((contact) => contact.status === "accepted").length;
    const userScore = 100 - timeoutContacts * 10 + acceptedContacts * 5;
    const clampedScore = Math.max(0, Math.min(100, userScore));

  return (
    <div onClick={() => {}} className="flex flex-row w-full gap-4">
      <div className='flex flex-row justify-center items-center w-18 h-18 p-4 rounded-full bg-blue-100'>
        <FaSearch className='text-blue-500 w-10 h-10' />
      </div>
      <div className='flex flex-col w-full'>
        <CountUp end={pendingContacts + timeoutContacts + acceptedContacts} duration={2} className='text-2xl font-bold' />
        <p className='w-full'>Bisherige Angebote für Suchen</p>
        {/* <p className='text-3xl text-allimmoDark font-bold'>{matches.length + pendingContacts + acceptedContacts}</p> */}
      </div>
        {/* <p className='text-sm text-center text-allimmoDark underline w-full mb-2'>Meine Suchprofile</p> */}
        {/* <div className='flex flex-col gap-1 w-full'> */}
            {/* <StatsItem showInfo={false} color={"gray"} label={"Suchen"} value={activeSearches} /> */}
            {/* <StatsItem color={"allimmo"} label={"Anfragen"} value={pendingContacts + timeoutContacts + acceptedContacts} />
            <StatsItem color={"orange"} label={"Offen"} value={pendingContacts} />
            <StatsItem color={"red"} label={"Abgelaufen"} value={timeoutContacts} />
            <StatsItem color={"green"} label={"Akzeptiert"} value={acceptedContacts} /> */}
            {/* <StatsItem color={"gray"} label={"Score"} value={clampedScore} /> */}
        {/* </div> */}
    </div>
  )
}

export default SearchesStats
