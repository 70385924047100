import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material';
import { deleteSingleUser } from '../../../helper/functions';
import validator from 'validator';

const DeleteSingleUser = () => {

    const [inputData, setInputData] = useState({
        userID: "",
        email: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState();

    const isButtonDisabled = (inputData.userID.trim() === "" && inputData.email.trim() === "") || (inputData.email.trim().length > 0 && !validator.isEmail(inputData.email));

    useEffect(() => {
        if(result?.message === true) {
            setInputData({
                userID: "",
                email: "",
            });
            
            console.log(result);
        }
        setIsLoading(false);
    }, [result]);    

    const deleteUser = async () => {
        setIsLoading(true);
        try {
            const result = await deleteSingleUser({userId: inputData.userID, email: inputData.email});
            setResult(result.data);
        } catch (error) {
            console.log(error.message);
        }
        return;
    };

    return (
        <div className='w-full flex flex-col justify-center items-center'>
            {(!result && !isLoading) && <p className='italic text-s mt-12 mb-10 w-600 text-center'>Hier kannst du nach Eingabe der Email Adresse oder der User ID den User komplett löschen. ACHTUNG! Diese Aktion kann nicht mehr rückgängig gemacht werden.</p>}

            {(!result && !isLoading) &&
            <div className={`w-full flex flex-col justify-center items-center ${isLoading ? "hidden" : "block"}`}>
                <input disabled={inputData.email !== ""} value={inputData.userID} onChange={(e) => setInputData({...inputData, userID: e.target.value})} type="text" placeholder="UserID eingeben..." className={`w-300 text-center p-1 border rounded border-allimmoDark`}/>
                <p className='italic my-3 font-semibold'>ODER</p>
                <input disabled={inputData.userID !== ""} value={inputData.email} onChange={(e) => setInputData({...inputData, email: e.target.value})} type="text" placeholder="Email-Adresse eingeben..." className={`w-300 text-center p-1 border rounded border-allimmoDark`}/>
            </div>}

            {(result && !isLoading && result.success) &&
            <div className='flex flex-col items-center justify-center mt-10 w-full'>
                {result.success &&
                <div className='flex flex-col items-center justify-center w-1/3'>
                    <p className='text-2xl underline'>User erfolgreich gelöscht</p>
                    <p className='italic text-center w-full mt-2'>Zusammenfassung</p>
                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>Email Adresse</p>
                        <p className='italic text-xs'>{result.summary.dEmail}</p>
                    </div>

                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>User ID</p>
                        <p className='italic text-xs'>{result.summary.dUserId}</p>
                    </div>

                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>Objekte</p>
                        <p className='italic text-xs'>{result.summary.dObjects}</p>
                    </div>

                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>Suchen</p>
                        <p className='italic text-xs'>{result.summary.dSearches}</p>
                    </div>

                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>Kontakte</p>
                        <p className='italic text-xs'>{result.summary.dContacts}</p>
                    </div>

                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>Chats</p>
                        <p className='italic text-xs'>{result.summary.dChats}</p>
                    </div>

                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>Matches</p>
                        <p className='italic text-xs'>{result.summary.dObjectsMatches + result.summary.dSearchesMatches}</p>
                    </div>
                    
                    <div className='flex flex-row gap-3 justify-center items-center'>
                        
                    </div>
                </div>}

                {!result.success &&
                <div>
                    <p className='text-2xl underline'>Ein Fehler ist aufgetreten</p>
                    <p className='italic text-xs'>{result.message}</p>
                </div>}
            </div>}

            {(result && !isLoading && result.message === "no-user-found") &&
            <div className='flex flex-col justify-center items-center mt-10'>
                <p className='text-center text-red-600 text-xl bg-red-100'>Kein User mit diesen Daten in der Datenbank gefunden</p>
                {inputData.email !== "" && <p className=''>Email: {inputData.email}</p>}
                {inputData.userID !== "" && <p className=''>User-ID: {inputData.userID}</p>}
            </div>}

            {isLoading && <CircularProgress className='mt-10' size={60} />}

            {!isLoading &&
            <div className='flex flex-row gap-3 mt-8'>
                {!result && <button onClick={deleteUser} disabled={isButtonDisabled} className={`border border-allimmoDark px-3 py-1 rounded ${isButtonDisabled ? "opacity-50" : "hover:bg-blue-300 bg-blue-200 cursor-pointer"}`}>User endgültig Löschen</button>}
                {result && <button onClick={() => setResult()} className={`border border-allimmoDark px-3 py-1 rounded bg-blue-200 cursor-pointer`}>Anderen User löschen</button>}
            </div>}
        </div>
    )
}

export default DeleteSingleUser
