import { MenuItem, TextField } from '@mui/material';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import Button from '../../Generics/Button';
import AnimationIcon from '../../Generics/AnimationIcon';
import StageLabel from './StageLabel';
import { orange, red } from '@mui/material/colors';
import ObjectCheckBox from './ObjectCheckBox';
import NavMenu from '../../Generics/NavMenu';

const Heating = ({back, next}) => {

    const { objectData, setObjectData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

    useEffect(() => {
      if(objectData.heating === "-") {
        setObjectData({...objectData, floorHeating: false});
      }
    }, [objectData.heating]);

  return (
    <div className='w-full ssm:w-3/4 md:w-1/2 flex flex-col justify-center items-center ssm:py-5 gap-2'>
        <StageLabel headline={objectData?.type === "commercial" ? t('CreateObject.Heating.CommercialHeadline') : ""} delay='no' color={false} stageIcon={require('../../../assets/animations/objectCreation/heating.json')} labelText={t('CreateObject.HeatingLabel')}/>
        <TextField required className='w-full rounded bg-white' size='medium' label={t('General.Heizung')} select value={objectData?.heating} onChange={(e) => setObjectData({...objectData, heating: e.target.value})}>
            <MenuItem value="-">-</MenuItem>
            <MenuItem value="gas">{t('Facilities.gas')}</MenuItem>
            <MenuItem value="fern">{t('Facilities.fern')}</MenuItem>
            <MenuItem value="electric">{t('Facilities.electric')}</MenuItem>
            {["house", "multi", "commercial"].includes(objectData.type) && <MenuItem value="oil">{t('Facilities.oil')}</MenuItem>}
            {["house", "multi", "commercial"].includes(objectData.type) && <MenuItem value="pump">{t('Facilities.pump')}</MenuItem>}
            {["house", "multi", "commercial"].includes(objectData.type) && <MenuItem value="pellets">{t('Facilities.pellets')}</MenuItem>}
            <MenuItem value="otherHeating">{t('Facilities.otherHeating')}</MenuItem>
        </TextField>
        {objectData.type !== "multi" &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <ObjectCheckBox _size={1.3} name="floorHeating" _color={red[300]} isDisabled={objectData.heating === "-"}/>
          <div className='grow'/>
          {(objectData.floorHeating && objectData.heating !== "-" ) && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/objectCreation/facilities/floorHeating.json`)}/>}
        </div>}

        {(!["multi", "commercial"].includes(objectData?.type) && (objectData?.type === "commercial" ? !["hall", "building"].includes(objectData?.commercialType) : true)) &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <ObjectCheckBox _size={1.3} name="chimney" _color={orange[800]}/>
          <div className='grow'/>
          {objectData.chimney && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/objectCreation/facilities/chimney.json`)}/>}
        </div>}

        {objectData.type === "commercial" && 
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <ObjectCheckBox _size={1.3} name="cooling" _color={orange[800]}/>
          <div className='grow'/>
          {objectData.cooling && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/objectCreation/facilities/cooling.json`)}/>}
        </div>}
        
        <NavMenu _back={back} nextBtn _next={next} nextDisabled={objectData.heating === "-"}/>
    </div>
  )
}

export default Heating
