import { CheckmarkCircle, CloseCircle } from "../../helper/icons";

const Input = ({placeholderText, handleKeyPress, inputValue, changeInputValue, inputLabel, isValid, width = "w-full", autocomplete = "off", borderColor = "slate", inputType = "text", showValidation = true, units = "", marginTop, height = "h-11"}) => {

  return (
    <div className={`flex flex-col justify-start items-start ${width} select-none`} style={{marginTop}}>
      <p className="text-12 pl-0.5">{inputLabel}</p>
      <div className={`flex flex-row justify-start items-center bg-white ${height} bg-white border-2 border-${borderColor} rounded-md pr-3 w-full space-x-3`}>
          <input className={`grow h-full outline-none select-none rounded-l-md pl-3 w-full `}
            autoComplete={autocomplete}          
            type={inputType}
            placeholder={placeholderText} 
            value={inputValue}
            onKeyDown={handleKeyPress}
            onChange={(e) => changeInputValue(e.target.value)} />
            {showValidation &&
            <div className={`flex flex-row justify-start items-center space-x-2`}>
              {!isValid && <CloseCircle className='text-red-400' size={20}/>}
              {isValid && <CheckmarkCircle className='text-green-500' size={20}/>}
            </div>}
            {units !== "" && <p className="text-12">{units}</p>}
      </div>
    </div>
  )
}

export default Input