import { t } from 'i18next';
import { HouseFill } from '../../helper/icons';
import { Checkbox } from '@mui/material';
import { useStateContext } from '../../contexts/ContextProvider';
import { auth } from '../../firebase.config';

const Page0 = ({changeInterfaceContact, contactInterface}) => {
  
  const  { customer } = useStateContext();
  
  return (
    <div className='flex flex-col items-center justify-center animate-fade gap-8 rounded-xl border border-gray-400 p-8 bg-white shadow-lg w-full'>
      <p className='text-center font-bold'>{t('Onboarding.Page0Header')}</p>
      
      <div className='flex flex-row items-center justify-center'>
        <div className='flex flex-row items-center justify-center p-4 border border-orange-300 rounded-xl shadow-md bg-orange-50'>
          <HouseFill size={70} className='text-orange-300 text-6xl'/>
        </div>
      </div>
      
      <div onClick={changeInterfaceContact} className='flex flex-col gap-1 items-center cursor-pointer'>
        <p className='text-center italic text-sm'>{t(`Onboarding.${(auth.currentUser && customer?.company) ? "Page0TextCompany" : "Page0Text"}`)}</p>
        {auth.currentUser && customer?.company &&
        <div onClick={changeInterfaceContact} className='flex flex-row gap-1 items-center rounded border-allimmoDark px-3 mt-4'>
          <Checkbox
            disableRipple
            disableTouchRipple
            value={contactInterface}
            checked={contactInterface}
          />
          <div>
            <p className='text-allimmoDark select-none'>{t('Onboarding.HierAnfordern')}</p>
            <p className='italic text-sm -mt-1 text-orange-400 animate-pulse'>{t('Onboarding.BetaAngebot')}</p>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default Page0
