import { createContext, useContext, useState } from "react";

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
    
    const [profileCompletionOpen, setProfileCompletionOpen] = useState("");
    const [buySearchOpen, setBuySearchOpen] = useState(false);
    const [videoOpen, setVideoOpen] = useState(false);
    const [objectVideoOpen, setObjectVideoOpen] = useState(false);
    const [searchVideoOpen, setSearchVideoOpen] = useState(false);
    const [mapVideoOpen, setMapVideoOpen] = useState(false);
    const [refundInfoOpen, setRefundInfoOpen] = useState(false);
    const [editSearchNameOpen, setEditSearchNameOpen] = useState(false);
    const [chatDeleteConfirmOpen, setChatDeleteConfirmOpen] = useState(false);
    const [objectListingObject, setObjectListingObject] = useState();
    const [objectDetailsOpen, setObjectDetailsOpen] = useState(false);
    const [searchDetailsOpen, setSearchDetailsOpen] = useState(false);
    const [updatesOpen, setUpdatesOpen] = useState(false);
    const [onboardingOpen, setOnboardingOpen] = useState(false);

    return (
        <PopupContext.Provider
            value={{
                profileCompletionOpen,
                objectListingObject,
                buySearchOpen,
                videoOpen,
                refundInfoOpen,
                editSearchNameOpen,
                objectVideoOpen,
                searchVideoOpen,
                chatDeleteConfirmOpen,
                mapVideoOpen,
                objectDetailsOpen,
                searchDetailsOpen,
                updatesOpen,
                onboardingOpen,
                setOnboardingOpen,
                setUpdatesOpen,
                setSearchDetailsOpen,
                setObjectDetailsOpen,
                setMapVideoOpen,
                setChatDeleteConfirmOpen,
                setSearchVideoOpen,
                setObjectVideoOpen,
                setEditSearchNameOpen,
                setRefundInfoOpen,
                setVideoOpen,
                setBuySearchOpen,
                setObjectListingObject,
                setProfileCompletionOpen,
            }}>
                {children}
        </PopupContext.Provider>
    )
};

export const usePopupContext = () => useContext(PopupContext);
