import * as turf from "@turf/turf";
import * as geohash from "ngeohash";

export const calculateGeohashAndDepthForSearch = (search) => {
  if (!search.polygon) return "no-areas";

  const polygonArray = search?.polygon.map(el => ({
    type: el.pm._shape.toLowerCase(),
    center: el.pm._shape === "Circle" ? el.getLatLng() : el.getBounds().getCenter(),
    coordinates: el.pm._shape === "Circle" ? el.getLatLng() : el.getLatLngs()[0],
    radius: el.pm._shape === "Circle" ? el.getRadius() : 0
  }));

  const searchGeohashes = calculateGeohashes(polygonArray);
  const searchDepth = calculateDepth(search, searchGeohashes);
  
  return {
    depth: searchDepth.facilities.depth + searchDepth.location.depth + searchDepth.size.depth,
    depthDetails: searchDepth,
    geohashes: searchGeohashes,
  };
};

const calculateGeohashes = (polygonArray) => {
  const geohashes = [];
  const HASH_PRECISION = 6;

  for(const area of polygonArray) {
    let geometry;

    if (area.type === "polygon") {

      const areaCoordinates = area.coordinates.map((point) => [point.lng, point.lat]);

      geometry = {
          type: "Polygon",
          coordinates: [
            [
              ...areaCoordinates,
              [area.coordinates[0].lng, area.coordinates[0].lat], // Closing the polygon
            ]
          ],
      };
    }

    if(area.type === "circle") {
      // center coordinates of the circle
      const centerLng = area.center.lng; // longitude
      const centerLat = area.center.lat; // latitude

      // radius of the circle in kilometers
      const radius = area.radius / 1000; // for 500 meters, use 0.5 km

      // create the circle geometry
      geometry = turf.circle([centerLng, centerLat], radius, {
        units: "kilometers",
        steps: 64, // number of sides (higher means smoother circle)
      });
    }

    // calculate the bounding box of the area
    try {
      turf.bbox(geometry);
      const bbox = turf.bbox(geometry);

      let involvedHashes = [];

      for(let i = 0; involvedHashes.length > 1000 || involvedHashes.length === 0; i++) {
        involvedHashes = generateGeohashesInBoundingBox(bbox, HASH_PRECISION - i);
      }

      const intersectingHashes = filterGeohashesByIntersection(involvedHashes, geometry);
      geohashes.push(...intersectingHashes);
    } catch (error) {
      console.log("ERROR CALCULATING BBOX:", error.message);
    }
  }
  const geohashSet = new Set(geohashes);
  const uniqueGeohashes = Array.from(geohashSet);

  return uniqueGeohashes;
};

const generateGeohashesInBoundingBox = (bbox, precision) => {
    // const {minLat, maxLat, minLng, maxLng} = bbox;

    const minLng = Math.min(bbox[0], bbox[2]);
    const minLat = Math.min(bbox[1], bbox[3]);
    const maxLng = Math.max(bbox[0], bbox[2]);
    const maxLat = Math.max(bbox[1], bbox[3]);
    const geohashes = new Set();
  
    const cellSizeLat = geohash.decode_bbox(geohash.encode(minLat, minLng, precision))[2] -
                        geohash.decode_bbox(geohash.encode(minLat, minLng, precision))[0];
    const cellSizeLng = geohash.decode_bbox(geohash.encode(minLat, minLng, precision))[3] -
                        geohash.decode_bbox(geohash.encode(minLat, minLng, precision))[1];

    for (let lat = minLat; lat <= maxLat; lat += cellSizeLat * 0.8) {
      for (let lng = minLng; lng <= maxLng; lng += cellSizeLng * 0.8) {
        const hash = geohash.encode(lat, lng, precision);
        geohashes.add(hash);
      }
    }

    return Array.from(geohashes);
};

const filterGeohashesByIntersection = (geohashes, areaGeometry) =>{
    const intersectingGeohashes = [];
  
    for(const hash of geohashes) {
      const [minLat, minLng, maxLat, maxLng] = geohash.decode_bbox(hash);
  
      // Create a GeoJSON polygon for the geohash cell
      const geohashCell = turf.polygon([
        [
          [minLng, minLat],
          [maxLng, minLat],
          [maxLng, maxLat],
          [minLng, maxLat],
          [minLng, minLat], // Closing the polygon
        ],
      ]);
  
      // Check for intersection
      if (turf.booleanIntersects(areaGeometry, geohashCell)) {
        intersectingGeohashes.push(hash);
      }
    }
  
    return intersectingGeohashes;
};

const calculateDepth = (search, geohashes) => {
  // return values: 0 = not usable, 1 = wide, 2 = normal, 3 = specific

  const geohashCount = geohashes.length;

  const getSizeDepth = () => {
      let returnValue = 0;
      let sizeDifference = "undefined";

      if(search?.size?.length === 0 || !search?.size) {
          returnValue = 0;
      } else {
          const sizes = [parseFloat(search?.size?.[0]),  parseFloat(search?.size?.[1])];
          sizeDifference = Math.max(...sizes) - Math.min(...sizes);

          if(search?.sizeUnlimited || sizeDifference > 100) returnValue = 1;
          if(sizeDifference > 50 && sizeDifference < 101) returnValue = 2;
          if(sizeDifference < 51) returnValue = 3;
      }

      return {
          depth: returnValue,
          sizeDifference: sizeDifference,
      }
  };

  const getLocationDepth = () => {
      if(geohashCount === 0) return {
          depth: 0,
          geohashes: 0,
          precision: 0,
      };

      let returnValue = 0;
      const hashPrecision = geohashes[0].length;
      
      if(hashPrecision === 6) {
          if(geohashCount <= 32) returnValue = 3;

          if(geohashCount > 32 && geohashCount <= 64) returnValue = 2;

          if(geohashCount > 64) returnValue = 1;
      }

      else if(hashPrecision === 5) {
          if(geohashCount === 1) returnValue = 3;

          if(geohashCount === 2) returnValue = 2;

          if(geohashCount > 2) returnValue = 1;
      }

      else if(hashPrecision === 4) returnValue = 1;

      return {
          depth: returnValue,
          geohashes: geohashCount,
          precision: hashPrecision,
      };
  };
  
  const getFacilitiesDepth = () => {
      let returnValue = 0;
      let selectedFacilities = [];

      if(search?.type === "apartment") {
          const apartmentFacilities = [
              {avoidCompensation: search?.avoidCompensation},
              {accessible: search?.accessible},
              {bathtub: search?.bathtub},
              {pets: search?.pets},
              {cellar: search?.cellar},
              {airCondition: search?.airCondition},
              {elevator: search?.elevator},
              {garage: search?.garage},
              {kitchen: search?.kitchen},
              {bike: search?.bike},
              {laundry: search?.laundry},
              {fitness: search?.fitness},
              {sauna: search?.sauna},
              {blinds: search?.blinds},
              {storageRoom: search?.storageRoom},
              {chimney: search?.chimney},
              {floorHeating: search?.floorHeating},
              {noGas: search?.noGas},
              {shortTermRent: search?.shortTermRent},
              {floor: search?.attic !== "-" || search?.groundFloor !== "-" || search?.exactFloor === true},
              {condition: (search?.new || search?.first || search?.renovation) && (!search?.new || !search?.first || !search?.renovation)},
              {furnished: ["furnished", "partFurnished"].includes(search?.furnished)},
              {building: ["old", "new"].includes(search?.building)},
              {openAreas: search?.balcony || search?.roofTop || search?.garden}
          ];

          selectedFacilities = apartmentFacilities.filter(facility => Object.values(facility)[0]);
          const count = selectedFacilities.length;

          if(count <= 2) returnValue = 1;
          if(count > 2 && count < 8) returnValue = 2;
          if(count >= 8) returnValue = 3;
      }

      if(search?.type === "house") {
          const houseFacilities = [
              {avoidCompensation: search?.avoidCompensation},
              {accessible: search?.accessible},
              {bathtub: search?.bathtub},
              {pets: search?.pets},
              {cellar: search?.cellar},
              {airCondition: search?.airCondition},
              {elevator: search?.elevator},
              {garage: search?.garage},
              {kitchen: search?.kitchen},
              {bike: search?.bike},
              {laundry: search?.laundry},
              {fitness: search?.fitness},
              {sauna: search?.sauna},
              {blinds: search?.blinds},
              {storageRoom: search?.storageRoom},
              {chimney: search?.chimney},
              {floorHeating: search?.floorHeating},
              {noGas: search?.noGas},
              {shortTermRent: search?.shortTermRent},
              {floor: search?.attic !== "-" || search?.groundFloor !== "-" || search?.exactFloor === true},
              {condition: (search?.new || search?.first || search?.renovation) && (!search?.new || !search?.first || !search?.renovation)},
              {furnished: ["furnished", "partFurnished"].includes(search?.furnished)},
              {building: ["old", "new"].includes(search?.building)},
              {openAreas: search?.balcony || search?.roofTop || search?.garden},
              {pool: search?.pool},
              {isolated: search?.isolated}
          ];

          selectedFacilities = houseFacilities.filter(facility => Object.values(facility)[0]);
          const count = selectedFacilities.length;

          if(count <= 2) returnValue = 1;
          if(count > 2 && count < 7) returnValue = 2;
          if(count >= 7) returnValue = 3;
      }

      if(search?.type === "land") {
          const landFacilities = [
              {buildingSite: search?.buildingSite},
              {developed: search?.developed},
              {noOldGrowth: search?.noOldGrowth}
          ];

          selectedFacilities = landFacilities.filter(facility => Object.values(facility)[0]);
          const count = selectedFacilities.length;

          if(count === 0) returnValue = 1;
          if(count === 1) returnValue = 2;
          if(count > 1) returnValue = 3;
      }

      return {
          depth: returnValue,
          facilities: selectedFacilities.length,
      };
  };

  return {
      size: getSizeDepth(),
      location: getLocationDepth(),
      facilities: getFacilitiesDepth(),
  };
};
