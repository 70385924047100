import { useRef } from "react";
import { CloseX } from "../helper/icons";
import useOutsideClick from "../hooks/useOutsideClick";
import { t } from "i18next";
const validator = require("validator");

const ContactPerson = ({personData, close}) => {
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => {
      close();
    });

    const fotoUrl = () => {
        const fotoType = typeof personData?.foto;

        if(fotoType === "string" && validator.isURL(personData?.foto)) {
            return <img className={`border border-black`} width={80} height={160} src={personData?.foto} />;
        }

        if(fotoType === "object") {
            const foto = personData?.foto[0];
            if(validator.isURL(foto?.daten?.[0]?.pfad?.[0])) {
                return <img className={`border border-black`} width={80} height={160} src={foto?.daten?.[0]?.pfad?.[0]} />;
            }
            return;
        }

        return;
    }

    return (
        <div ref={wrapperRef} className="flex flex-col items-center justify-center px-5 py-1 gap-2">
            <div className="w-full flex flex-row justify-between">
                <p>{t('General.Kontakt')}</p>
                <CloseX className="cursor-pointer hover:opacity-40" size={18} onClick={close}/>
            </div>
            {fotoUrl()}
            <p className="text-lg underline">{`${personData?.anrede} ${personData?.vorname} ${personData?.name}`}</p>
            <div className="flex flex-col items-center justify-center mb-5">
                {![undefined, "undefined"].includes(personData?.email) && <p>{personData?.email}</p>}
                {![undefined, "undefined"].includes(personData?.phone) && <p>{personData?.phone}</p>}
            </div>
        </div>
    );
};

export default ContactPerson;
