import { t } from 'i18next';
import { ContactMatch, ObjectList } from '../../helper/icons';

const Page4 = () => {
  return (
    <div className='flex flex-col items-center justify-center animate-fade gap-8 rounded-xl border border-gray-400 p-6 bg-white shadow-lg w-full'>
      <p className='text-center font-bold'>{t('Onboarding.Page4Header')}</p>
      
      <div className='flex flex-row items-center justify-center gap-3'>
        <div className='animate-fade animate-delay-300 flex flex-row items-center justify-center p-4 border border-orange-300 rounded-xl shadow-md bg-orange-50'>
          <ObjectList size={70} className='text-orange-300 text-6xl'/>
        </div>

        <div className='animate-fade animate-delay-[600ms] flex flex-row items-center justify-center p-4 border border-orange-300 rounded-xl shadow-md bg-orange-50'>
          <ContactMatch size={70} className='text-orange-300 animate-pulse text-6xl'/>
        </div>
        
      </div>

      <p className='text-center italic text-sm'>{t('Onboarding.Page4Text')}</p>
    </div>
  )
}

export default Page4
