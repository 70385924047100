import { green } from "@mui/material/colors";
import { t } from "i18next";
import { useEffect } from "react";
import { useCreationContext } from "../../../contexts/CreationProvider";
import AnimationIcon from "../../Generics/AnimationIcon";
import NavMenu from "../../Generics/NavMenu";
import StageLabel from "../../Objects/Creation/StageLabel";
import SearchCheckBox from "./SearchCheckBox";
import { Collapse } from "@mui/material";

const Kitchen = ({back, next}) => {

    const { searchData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-3/4 md:w-1/2 mdd:w-1/3 mt-10 gap-1">
        <StageLabel labelText={t('CreateSearch.Kitchen.Label')} headline={t('CreateSearch.Kitchen.Headline')}/>
        
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <SearchCheckBox _size={1.3} name="kitchenRoom" _color={green[700]}/>
          <div className='grow'/>
          {searchData.floorHeating && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/floorHeating.json`)}/>}
        </div>
        
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <SearchCheckBox _size={1.3} name="kitchenEdge" _color={green[600]}/>
          <div className='grow'/>
          {searchData.noGas && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/gas.json`)}/>}
        </div>

        {searchData?.type !== "commercial" &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <SearchCheckBox _size={1.3} name="kitchenLiving" _color={green[500]}/>
          <div className='grow'/>
          {searchData.chimney && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/fireplace.json`)}/>}
        </div>}
        
        {searchData?.type === "commercial" &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <SearchCheckBox _size={1.3} name="cooling" _color={green[500]}/>
          <div className='grow'/>
          {searchData.cooling && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/cooling.json`)}/>}
        </div>}

        <NavMenu _back={back} nextBtn _next={next}/>
    </div>
  )
};

export default Kitchen
