import { t } from "i18next";
import { Copyright, Dot } from "../helper/icons";
import Flags from "./Flags";

const CopyrightInfo = () => {

  return (
    <div className="flex fixed bottom-3 flex-col items-center justify-center w-full text-allimmoDark/40">
      <div className="flex flex-row items-center justify-center w-full gap-2 text-allimmoDark/40">
          <p onTouchStart={() => window.open("https://allimmo.at/allgemeine-geschaeftsbedingungen/", "_blank")} onClick={() => window.open("https://allimmo.at/allgemeine-geschaeftsbedingungen/", "_blank")} className="cursor-pointer hover:underline text-sm">{t('Menu.AGB')}</p>
          <Dot size={8}/>
          <p onTouchStart={() => window.open("https://www.allimmo.at/datenschutzerklaerung/", "_blank")} onClick={() => window.open("https://www.allimmo.at/datenschutzerklaerung/", "_blank")} className="cursor-pointer hover:underline text-sm">{t('Menu.Datenschutz')}</p>
          <Dot size={8}/>
          <p onTouchStart={() => window.open("https://allimmo.at/impressum/", "_blank")} onClick={() => window.open("https://allimmo.at/impressum/", "_blank")} className="cursor-pointer hover:underline text-sm">{t('Menu.Impressum')}</p>
      </div>
      {/* <Flags /> */}
    </div>
  );
};

export default CopyrightInfo
