import { t } from "i18next";
import Button from "../Generics/Button";
import { useStateContext } from "../../contexts/ContextProvider";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { auth, firestore } from "../../firebase.config";
import { CircularProgress } from "@mui/material";

const ActivateAutoContactSetting = ({confirm, close}) => {
  const { customer } = useStateContext();

  const [isLoading, setIsLoading] = useState(false);
  const [matchesCount, setMatchesCount] = useState(0);
  const [insufficientCredits, setInsufficientCredits] = useState(true);
   
  useEffect(() => {
    getNumberOfMatches();
  }, []);

  useEffect(() => {
    setInsufficientCredits(parseInt(customer.credits) < parseInt(matchesCount));
  }, [matchesCount]);

  const getNumberOfMatches = async () => {
      setIsLoading(true);
      const wantNormalSearches = [undefined, true].includes(customer?.settings?.autoContactSearchDepth?.normal);
      const wantAccurateSearches = [undefined, true].includes(customer?.settings?.autoContactSearchDepth?.genau);
      const wantInaccurateSearches = [undefined, true].includes(customer?.settings?.autoContactSearchDepth?.ungenau);

      const matchesRef = query(collection(firestore, "matches"), where("status", "==", "matched"), where("objectUser", "==", auth.currentUser.uid));
      const matchResults = await getDocs(matchesRef);

      const accurateMatchesToContact = wantAccurateSearches ? (matchResults.docs.filter(search => search.data().searchDepth >= 7).length) : 0;
      const normalMatchesToContact = wantNormalSearches ? (matchResults.docs.filter(search => search.data().searchDepth >= 5 && search.data().searchDepth <= 6).length) : 0;
      const inaccurateMatchesToContact = wantInaccurateSearches ? matchResults.docs.filter(search => search.data().searchDepth <= 4).length : 0;

      setMatchesCount(accurateMatchesToContact + normalMatchesToContact + inaccurateMatchesToContact);
      setIsLoading(false);
  };  

  return (
    <div className="px-2 py-8 flex flex-col justify-center items-center">
        <p className="text-lg text-allimmoDark text-center">{t('Einstellungen.AutoContactFrage')}</p>
        <p className="mt-1 px-8 w-full text-center text-sm">{t('Einstellungen.AutoContactTextAktivieren')}</p>
        {isLoading && <CircularProgress className="mt-5 mb-3" size={25} />}

        {(!isLoading) &&
        <div className="flex flex-col items-center justify-center mt-5 mb-2 border rounded-lg px-8 py-1.5 border-dashed border-allimmoDark/50">
            <div className="text-allimmoDark text-sm sm:text-lg w-full justify-center flex gap-1 flex-row items-center">
                <p>{t("General.DerzeitHastDu")}</p>
                {!isLoading && <p className="text-center font-bold">{matchesCount.toLocaleString()}</p>}
                {isLoading && <CircularProgress size={20} />}
                <p>{t("General.PassendeMatches")}</p>
            </div>

            <div className="text-allimmoDark text-sm sm:text-lg w-full justify-center flex gap-1 flex-row items-center -mt-1">
                <p className="italic">{t("General.Und")}</p>
                <p className={`font-bold text-center ${insufficientCredits ? "text-red-500 animate-pulse" : ""}`}>{customer.credits.toLocaleString()}</p>
                <p className="italic">{t("General.Credits")}</p>
            </div>
        </div>}

        <div className="flex flex-row justify-center items-center space-x-2 mt-5">
            <Button isDisabled={insufficientCredits || isLoading} text={t('General.Aktivieren')} clickFunction={confirm}/>
            <Button text={t('General.Abbrechen')} clickFunction={close}/>
        </div>
    </div>
  )
};

export default ActivateAutoContactSetting;
