import { t } from 'i18next';
import { useEffect } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import AnimationIcon from '../../Generics/AnimationIcon';
import NavMenu from '../../Generics/NavMenu';
import SquareButton from '../../Generics/SquareButton';
import StageLabel from './StageLabel';

const Condition = ({next, back}) => {

    const { objectData, setObjectData } = useCreationContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleCondition = (newCondition) => {
        setObjectData({...objectData, condition: newCondition});
        setTimeout(() => {
            next();
        }, "150");
    };

  return (
    <div className="flex flex-col justify-center items-center lg:w-1/2">
        {objectData.type === "multi" ? 
        <StageLabel labelText={t('CreateObject.ConditionLabelMulti')}/> :
        <StageLabel labelText={t('CreateObject.ConditionLabel')}/>}
        <div className="grid grid-cols-2 ssm:grid-cols-3 gap-2 mt-5">
            <SquareButton selected={objectData.condition === "first"} clickFunction={() => handleCondition("first")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/first.json')}/>} label={t('Conditions.first')}/>
            <SquareButton selected={objectData.condition === "new"} clickFunction={() => handleCondition("new")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/new.json')}/>} label={t('Conditions.new')}/>
            <div className='col-span-2 ssm:col-span-1 place-self-center'>
                <SquareButton selected={objectData.condition === "renovation"} clickFunction={() => handleCondition("renovation")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/renovation.json')}/>} label={t('Conditions.renovationShort')}/>
            </div>
        </div>
        <NavMenu _back={back} />
    </div>
  )
};

export default Condition
