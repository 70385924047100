import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import Popup from '../components/Generics/Popup';
import AddMissingData from '../components/Popups/AddMissingData';
import { useStateContext } from '../contexts/ContextProvider';
import { useCreationContext } from '../contexts/CreationProvider';
import { AddObject, Apartment, CheckmarkCircle, CircleIcon, CloseCircle, Dot, Hourglass, House, Land, Plus, Prospects, Rent, Sale, Stopwatch } from '../helper/icons';
import ObjectDetails from './ObjectDetails';
import 'react-data-grid/lib/styles.css';
import { DataGrid, gridNumberComparator } from '@mui/x-data-grid';
import { deDE } from '@mui/x-data-grid/locales';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import i18n from '../i18n';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { auth, firestore } from '../firebase.config';
import { useSelectionContext } from '../contexts/SelectionProvider';

const Objects = () => {

    const { handleMenuState, objectsContacts, matches } = useStateContext();
    const { resetObjectData } = useCreationContext();
    const { objectToOpen, setObjectToOpen } = useSelectionContext();

    const [dataLoading, setDataLoading] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [unfilteredData, setUnfilteredData] = useState([]);
    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState([]);
    const pageSize = 10;

    const ignoreList = ["contactPerson"];

    const theme = createTheme({
        typography: {
            fontFamily: '"PT Sans", sans-serif', // Set global font family
        },
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    footer: {
                        backgroundColor: '#f5f5f5', // Change footer background color here
                    },
                    root: {
                        border: 'none', // Removes the grid border
                        overflow: 'hidden', // Hides the scrollbar
                    },
                    row: {
                        border: '1px solid #00547B40', // Removes the border under each row
                        borderRadius: '4px',
                        marginBottom: '4px',
                        '&:hover': {
                            borderColor: '#00547B', // Set hover background color
                            backgroundColor: 'white', // Set hover background color
                        },
                    },
                    columnHeaders: {
                        display: 'none', // Hides the header
                    },
                },
            },
        },
        palette: {
          primary: { main: '#1976d2' },
        },
    }, 
    i18n.resolvedLanguage === "de" ? deDE : null, // x-data-grid translations
    );

    useEffect(() => {
        loadObjects();
    }, []);

    useEffect(() => {
        if(filters.length === 0) {
            setFilteredRows(unfilteredData);
        }
    }, [filters]);

    const loadObjects = async () => {
        setDataLoading(true);

        const objectsQuery = query(collection(firestore, "objects"), where("customer", "==", auth.currentUser.uid), where("deleted", "==", false));
        onSnapshot(objectsQuery, (result) => {
            const flatenedData = result.docs.map((doc, i) => ({
                ...doc.data(), 
                id: doc.id, 
            }));
            setUnfilteredData(flatenedData);
            setFilteredRows(flatenedData);
            setDataLoading(false);
      });
    };
  
    const handleNewObject = () => {
        resetObjectData();
        handleMenuState("newObject");
    };

    useEffect(() => {
        let newFilteredData = unfilteredData;
        console.log(filters);

        for(const f of filters) {
            if(["sale", "rent"].includes(f)) {
                newFilteredData = newFilteredData.filter((row) => {
                    return row.deal === f;
                });
            }

            if(["house", "apartment"].includes(f)) {
                newFilteredData = newFilteredData.filter((row) => {
                    return row.type === f;
                });
            }

            if(["matches"].includes(f)) {
                newFilteredData = newFilteredData.filter((row) => {
                    return matches.filter((m) => m.objectId === row.id).length > 0;
                });
            }

            if([true, false].includes(f)) {
                newFilteredData = newFilteredData.filter((row) => {
                    return row.active === f;
                });
            }
        }
        setFilteredRows(newFilteredData);
    }, [filters]);

    const handleUserFilter = (type) => {
        if(type === "reset") {
            setFilters([]);
            return;
        }

        if(!filters.includes(type.value)) {
            setFilters([...filters, type.value]);
        } else {
            setFilters(filters.filter(f => f !== type.value));
        }
        return;      
    };

    const getRowClass = (params) => {
        return 'bg-white'; // Apply a custom class for all rows
        if (params.row.active) {
            return 'bg-green-50'; // Apply a custom class for inactive rows
        } else {
            return 'bg-red-50'; // Apply a custom class for active rows
        }
    };

    const dataColumns = [
        {
            field: "address",
            flex: 1,
            headerName: "Adresse",
            renderCell: (params) => {
                const missingData = params.row?.missingParams?.length > 0;
                const undefinedData = params.row?.undefinedParams?.filter(el => !ignoreList.some(prefix => el.startsWith(prefix))).length > 0;
                const prospects = matches.filter(m => m.objectId === params.row?.id).length;
                const address = params.row?.address.streetNumber + " " + params.row?.address.cityZip;
                const TruncatedText = ({ text, maxLength }) => {
                    const truncatedText =
                      text.length > maxLength ? text.slice(0, maxLength) + "…" : text;
                  
                    return <p className={`text-allimmoDark text-13 sm:text-14 md:text-16 grow  ${address?.length > maxLength ? "truncate" : ""} ${truncatedText.includes("missing") ? "text-gray-500" : ""}`}>{`${truncatedText.includes("missing") ? t('MissingData.Address') : truncatedText}`}</p>;
                };

                return (
                    <div className='flex flex-col w-full -mt-1.5'>
                        <div className={`flex flex-row items-center gap-2 -my-3.5 pr-1`}>
                            <div className='flex flex-row items-center justify-center gap-0.5'>
                                <Dot size={20} className={`cursor-pointer ${params.row.active ? 'text-green-600' : "text-red-600"}`}/>
                                <Apartment size={18} className={`cursor-pointer text-allimmoDark ${params.row.type === "apartment" ? 'block' : "hidden"}`}/>
                                <House size={18} className={`cursor-pointer text-allimmoDark ${params.row.type === "house" ? 'block' : "hidden"}`}/>
                                <Land size={18} className={`cursor-pointer text-allimmoDark ${params.row.type === "land" ? 'block' : "hidden"}`}/>
                                <Rent size={18} className={`cursor-pointer text-allimmoDark ${params.row.deal === "rent" ? 'block' : "hidden"}`}/>
                                <Sale size={18} className={`cursor-pointer text-allimmoDark ${params.row.deal === "sale" ? 'block' : "hidden"}`}/>
                            </div>
                            <TruncatedText text={address} maxLength={40} />
                            {/* <p className={`text-allimmoDark text-13 sm:text-14 md:text-16 grow ${address?.length > 25 ? "truncate" : ""} ${address.includes("missing") ? "text-gray-500" : ""}`}>{`${address.includes("missing") ? t('MissingData.Address') : address}`}</p> */}
                            <div className='flex flex-row items-center justify-end gap-0.5 w-full'>
                                {/* {params.row?.contactPerson !== "undefined" && <PersonCircle onClick={(e) => {e.stopPropagation(); setShowContactPerson(params.row?.id)}} size={20} className={`cursor-pointer hover:opacity-50`}/>} */}
                                <Prospects size={20} className='text-allimmoDark'/>
                                <p className='text-13 sm:text-14 md:text-16'>{prospects}</p>
                            </div>
                        </div>
                        <div className='flex flex-row items-center w-full gap-1.5 ml-1.5 -mt-1'>
                            {/* <p className='text-13 sm:text-14'>Kontakte:</p> */}
                            <div className='flex flex-row items-center justify-center px-1 h-6 gap-2.5 rounded bg-gray-100 border-gray-400 border'>
                                <div className='flex flex-row justify-start items-center'>
                                    <CheckmarkCircle size={18} className={`text-green-500`}/>
                                    <p className='sm:ml-1 text-sm'>{`${objectsContacts.filter(c => c.object === params.row.id && c.status === "accepted").length}`}</p>
                                </div>

                                <div className='flex flex-row justify-start items-center'>
                                    <Hourglass size={18} className={`text-orange-500`}/>
                                    <p className='sm:ml-1 text-sm'>{`${objectsContacts.filter(c => c.object === params.row.id && c.status === "pending").length}`}</p>
                                </div>

                                <div className='flex flex-row justify-start items-center'>
                                    <Stopwatch size={16} className={`text-red-500`}/>
                                    <p className='sm:ml-1 text-sm'>{`${objectsContacts.filter(c => c.object === params.row.id && c.status === "timeout").length}`}</p>
                                </div>
                            </div>
                            {(missingData || (undefinedData && !params.row?.ignoreUndefined)) &&
                            <div className='flex flex-row items-center w-full text-xs gap-3 pl-1 pr-2.5'>
                                {/* {(params.row.undefinedParams.length > 0 || params.row.missingParams.length > 0) && <p>{`${t('MissingData.Label')}:`}</p>} */}
                                <IncompleteDataInfo objectData={params.row} missingType="missing" animate color="red" />
                                {!params.row?.ignoreUndefined && 
                                <IncompleteDataInfo objectData={params.row} missingType="undefined" color="blue" ignorePrefixes={ignoreList} />}
                                {params.row?.externId !== "undefined" && <p className='italic text-xs grow text-end'>{params.row.externId}</p>}
                            </div>}
                        </div>
                    </div>
                )
            },
            filterable: false,
        },
    ];

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const FilterButton = ({type, label, icon, color ="text-allimmoDark", hide = false}) => {
        return (
            <div onClick={() => hide ? null : handleUserFilter(type)} className={`flex w-14 h-12 ${hide ? "border-dashed border-gray-200 text-gray-200 bg-white" : filters.includes(type.value) ? "bg-allimmoDark/70 cursor-pointer shadow-xl text-white opacity-100 border-solid hover:opacity-50" : `${color} opacity-50 cursor-pointer border-dashed hover:opacity-100 bg-white`} flex-col items-center justify-center rounded-xl px-3 py-1 border border-allimmoDark`}>
                <div className='h-5 w-full flex flex-row items-center justify-center'>
                    {icon}
                </div>
                <p className='text-xs italic'>{label}</p>
            </div>
        )
    };

    return (
        <div className='w-full lg:w-2/3 flex flex-col items-center px-1 sm:px-3 py-5'>
            {/* <div className={`flex flex-col items-center mmd:hidden mb-6`}>
                <AddObject onClick={handleNewObject} className='text-allimmoDark/80 hover:opacity-50 cursor-pointer' size={28} />
            </div> */}
            
            <div className='border-b border-allimmoDark/40 pb-2.5 flex flex-col mmd:flex-row items-center justify-end w-full gap-1 mb-2.5'>
                <div className='flex flex-row items-center gap-1 justify-end w-full'>
                    <div  className={`mmd:flex grow h-12 flex-row items-center hidden`}>
                        <div onClick={handleNewObject} className='flex flex-row cursor-pointer hover:opacity-50 h-full w-14 items-center rounded-xl justify-center px-3 py-1 border-dashed border border-allimmoDark/80 bg-allimmoDark/5'>
                            <Plus className='text-allimmoDark/80' size={24} />
                        </div>
                    </div>
                    <FilterButton hide={filters.includes(false)} type={{field: "active", value: true}} label="Aktiv" icon={<Dot size={20} />}/>
                    <FilterButton hide={filters.includes(true)} type={{field: "active", value: false}} label="Inaktiv" icon={<CircleIcon size={12} />}/>
                    <FilterButton hide={filters.includes("sale")} type={{field: "deal", value: "rent"}} label="Miete" icon={<Rent size={20} />}/>  
                    <FilterButton hide={filters.includes("rent")} type={{field: "deal", value: "sale"}} label="Kauf" icon={<Sale size={20} />}/>
                </div>

                <div className='flex flex-row items-center gap-1 justify-end w-full mmd:w-auto'>
                    <div  className={`mmd:hidden grow h-12 flex-row items-center flex`}>
                        <div onClick={handleNewObject} className='flex flex-row cursor-pointer hover:opacity-50 h-full w-14 items-center rounded-xl justify-center px-3 py-1 border-dashed border border-allimmoDark/80 bg-allimmoDark/5'>
                            <Plus className='text-allimmoDark/80' size={24} />
                        </div>
                    </div>
                    
                    <FilterButton hide={filters.includes("house")} type={{field: "type", value: "apartment"}} label="Wohnung" icon={<Apartment size={20} />}/>
                    <FilterButton hide={filters.includes("apartment")} type={{field: "type", value: "house"}} label="Haus" icon={<House size={20} />}/>
                    {/* <FilterButton type="missing" label="Fehlend" icon={<CloseX size={20} />}/> */}
                    <FilterButton type={{field: "matches", value: "matches"}}  label="Matches" icon={<Prospects size={20} />}/>
                    <div onClick={() => filters.length === 0 ? null : handleUserFilter("reset")} className={`flex w-14 h-12 ${filters.length === 0 ? "bg-white border-dashed border-gray-200 text-gray-200" : `bg-white shadow-xl border-red-400 text-red-400 cursor-pointer border-dashed hover:opacity-100`} flex-col items-center justify-center rounded-xl px-3 py-1 border`}>
                        <div className='h-5 w-full flex flex-row items-center justify-center'>
                            <CloseCircle size={20} />
                        </div>
                        <p className='text-xs italic'>Reset</p>
                    </div>
                </div>
            </div>
            <div className='min-h-760 w-999 overflow-hidden'>
            <ThemeProvider theme={theme}>
                <DataGrid
                rowHeight={65}
                sx={{
                    // This removes the blue outline from all cells
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                        display: 'none',
                    },
                    // This removes the blue outline from the parent cell container
                    '& .MuiDataGrid-cell:focus-within': {
                      outline: 'none'
                    },
                    '& .MuiDataGrid-cell': {
                        display: 'flex',
                        flexDirection: 'column', // Force flex-column layout
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderTop: 'none', // Removes the thin line
                    },
                    '& .MuiTablePagination-root': {
                        display: filteredRows.length < pageSize ? 'none' : '', // Hides only pagination controls
                    },
                    '& .MuiDataGrid-overlay': {
                        top: 0,  // Move overlay to the top
                        alignItems: 'flex-start', // Align content to the top
                        paddingTop: '10px', // Add spacing
                        backgroundColor: 'transparent', // Make loading background transparent
                    },
                }}
                disableColumnMenu
                disableColumnSorting
                disableColumnSelector
                disableColumnFilter
                disableRowSelectionOnClick
                disableMultipleRowSelection
                disableColumnResize
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: pageSize, page: page }, // Default to 10 rows per page
                    },
                }} 
                onRowClick={(row) => setObjectToOpen(row.row)}
                onPageChange={handlePageChange} 
                pageSizeOptions={[10, 25, 50]} 
                paginationMode="client" 
                loading={dataLoading} 
                className='w-full cursor-pointer' 
                getRowClassName={getRowClass}
                rows={filteredRows} 
                columns={dataColumns}
                sortModel={[{ field: 'address', sort: 'asc' }]}
                />
            </ThemeProvider>
            </div>          
            
            <Popup 
            header={""} 
            openState={objectToOpen} 
            content={<ObjectDetails objectSelection={objectToOpen}/>} 
            close={() => setObjectToOpen()} />
        </div>
    )
};

const IncompleteDataInfo = ({objectData, missingType, color, animate = false, ignorePrefixes = []}) => {

    const { screenSize } = useStateContext();
    const [addDataOpen, setAddDataOpen] = useState(false);

    const clickHandler = (e) => {
        e.stopPropagation();
        setAddDataOpen(true);
    };

    const handleClose = () => {
        setAddDataOpen(false);
    };

    const incompleteDataArrayName = missingType + "Params";

    return (
        <div className={`${objectData?.[incompleteDataArrayName]?.filter(el => !ignorePrefixes.some(prefix => el.startsWith(prefix)))?.length > 0 ? "block" : "hidden"} h-6 px-1 bg-${color}-50 rounded border-${color}-400 border flex flex-row items-center justify-center`}>
            <p onClick={(e) => clickHandler(e)} className={`${animate ? "animate-pulse" : ""} text-${color}-400`}>{`${t(`MissingData.${missingType}`)}: ${objectData[incompleteDataArrayName]?.length}`}</p>
            <Popup
            content={<AddMissingData close={handleClose} obj={objectData} arrayName={incompleteDataArrayName}/>}
            full={screenSize.width < 500 ? true : false}
            header={t('MissingData.AddMissingDataPopup')}
            openState={addDataOpen}
            showLogo={false}
            />
        </div>
    )
};

export default Objects
